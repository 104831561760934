
import React, { useState } from 'react';
import { Label } from '@/components/ui/label';
import { Loader2 } from 'lucide-react';

interface FileUploadAreaProps {
  onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  uploading: boolean;
}

const FileUploadArea = ({ onFileChange, uploading }: FileUploadAreaProps) => {
  const [dragActive, setDragActive] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
    onFileChange(e);
  };

  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      setSelectedFile(file);

      // Create a synthetic event to pass to onFileChange
      const syntheticEvent = {
        target: {
          files: e.dataTransfer.files,
        },
      } as React.ChangeEvent<HTMLInputElement>;

      onFileChange(syntheticEvent);
    }
  };

  return (
    <div
      className={`flex items-center justify-center ${
        uploading ? 'opacity-50' : ''
      }`}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}>
      <Label
        htmlFor='file-upload'
        className={`
          cursor-pointer bg-background 
          border-2 ${
            dragActive ? 'border-primary' : 'border-dashed border-input'
          } 
          rounded-lg p-8 flex flex-col items-center justify-center w-full
          transition-colors duration-200 ease-in-out
          min-h-[200px] touch-manipulation
        `}>
        <div className='flex flex-col items-center justify-center space-y-4'>
          {uploading ? (
            <Loader2 className='h-12 w-12 animate-spin text-muted-foreground' />
          ) : (
            <svg
              className='mx-auto h-12 w-12 text-muted-foreground'
              stroke='currentColor'
              fill='none'
              viewBox='0 0 48 48'
              aria-hidden='true'>
              <path
                d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                strokeWidth={2}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          )}

          {selectedFile ? (
            <div className='text-center'>
              <p className='text-sm font-medium text-primary'>
                Selected: {selectedFile.name}
              </p>
              <p className='text-xs text-muted-foreground mt-1'>
                {(selectedFile.size / (1024 * 1024)).toFixed(2)}MB
              </p>
            </div>
          ) : (
            <>
              <div className='flex flex-col text-sm text-center text-muted-foreground'>
                <span className='relative rounded-md font-medium text-primary text-base mb-1'>
                  {uploading ? 'Uploading...' : 'Upload a file'}
                </span>
                <p className='text-sm'>Tap here or drag and drop</p>
              </div>
              <div className='text-xs text-muted-foreground text-center'>
                <p>Supported: JPG, PNG, WEBP</p>
                <p className='mt-1'>Maximum size: 5MB</p>
              </div>
            </>
          )}
        </div>
        <input
          id='file-upload'
          name='file-upload'
          type='file'
          className='sr-only'
          accept='image/png, image/jpeg, image/webp'
          onChange={handleChange}
          disabled={uploading}
        />
      </Label>
    </div>
  );
};

export default FileUploadArea;
