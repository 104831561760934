import React, { useState, useEffect } from 'react';
import { Layout } from '@/components/Layout';
import { useLocationsQuery } from '@/hooks/useLocationQuery';
import { useServicesQuery } from '@/hooks/useServiceQuery';
import { useLocationServicesByLocationQuery } from '@/hooks/useLocationServiceQuery';
import LocationSpecificServiceCard from '@/components/service/LocationSpecificServiceCard';
import { Card, CardContent } from '@/components/ui/card';
import { MapPin } from 'lucide-react';
import PageMeta from '@/components/SEO/PageMeta';
import Breadcrumbs from '@/components/SEO/Breadcrumbs';
import { withPageTracking } from '@/components/tracking/TrackingProvider';
import { Button } from '@/components/ui/button';
import { useSearchParams, Link } from 'react-router-dom';

const ServicesByLocation: React.FC = () => {
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const { data: locations, isLoading: locationsLoading } = useLocationsQuery();
  const { data: services, isLoading: servicesLoading } = useServicesQuery();
  
  const {
    data: locationServices,
    isLoading: locationServicesLoading
  } = useLocationServicesByLocationQuery(
    selectedLocation?.id ? String(selectedLocation.id) : undefined
  );
  
  // Get location from URL parameter if provided
  const [searchParams] = useSearchParams();
  const locationSlug = searchParams.get('location');
  
  // Select location based on URL parameter or default to first location
  useEffect(() => {
    if (locations && locations.length > 0 && !selectedLocation) {
      if (locationSlug) {
        // Find location by slug from URL parameter
        const locationFromSlug = locations.find(loc => loc.slug === locationSlug);
        if (locationFromSlug) {
          setSelectedLocation(locationFromSlug);
        } else {
          // Fallback to first location if slug not found
          setSelectedLocation(locations[0]);
        }
      } else {
        // No slug in URL, use first location
        setSelectedLocation(locations[0]);
      }
    }
  }, [locations, selectedLocation, locationSlug]);
  
  // Determine loading state
  const isLoading = locationsLoading || (selectedLocation && locationServicesLoading);
  
  return (
    <Layout>
      <PageMeta
        title="Masonry Services by Location | Fix My Brick"
        description="Find specialized masonry and brick repair services in your area. Browse our complete services offering by location."
        canonical="/services-by-location"
      />
      
      <div className="container mx-auto px-4 py-4">
        <Breadcrumbs
          items={[
            { label: 'Home', path: '/' },
            { label: 'Services by Location', path: '/services-by-location' },
            selectedLocation ? { label: selectedLocation.name, path: `/services-by-location?location=${selectedLocation.slug}` } : null,
          ].filter(Boolean)}
          className="py-2"
        />
      </div>
      
      <div className="bg-gray-50 py-12 md:py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center mb-12">
            <h1 className="text-3xl md:text-4xl font-bold mb-4">Find Masonry Services in Your Area</h1>
            <p className="text-gray-600">
              Browse our specialized masonry and brick repair services available in your location.
              Each area has tailored services to meet local needs and building requirements.
            </p>
          </div>
          
          {/* Location selector section */}
          <div className="mb-12">
            <h2 className="text-2xl font-bold mb-6 text-center">Select Your Location</h2>
            
            {locationsLoading ? (
              <div className="flex justify-center">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#D00B0E]"></div>
              </div>
            ) : (
              <div className="flex flex-wrap justify-center gap-4">
                {locations?.map(location => (
                  <Button
                    key={location.id}
                    variant={selectedLocation?.id === location.id ? "default" : "outline"}
                    className={`flex items-center gap-2 px-4 py-2 ${
                      selectedLocation?.id === location.id ? 'bg-[#D00B0E] text-white' : 'text-gray-700'
                    }`}
                    onClick={() => setSelectedLocation(location)}
                  >
                    <MapPin className="h-4 w-4" />
                    <span>{location.name}</span>
                  </Button>
                ))}
              </div>
            )}
          </div>
          
          {/* Services grid section */}
          {selectedLocation && (
            <div>
              <div className="mb-8 text-center">
                <h2 className="text-2xl md:text-3xl font-bold mb-3">
                  Services in {selectedLocation.name}
                </h2>
                <p className="text-gray-600 max-w-2xl mx-auto">
                  Our professional masonry services available in the {selectedLocation.name} area. 
                  Click on a service to learn more about our location-specific offerings.
                </p>
              </div>
              
              {isLoading ? (
                <div className="flex justify-center">
                  <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#D00B0E]"></div>
                </div>
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {services?.map(service => {
                    // Find if there's a location-specific version of this service
                    const locationService = locationServices?.find(
                      ls => ls.service_id === service.id
                    );
                    
                    return (
                      <LocationSpecificServiceCard
                        key={service.id}
                        service={service}
                        location={selectedLocation}
                        locationService={locationService}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default withPageTracking(ServicesByLocation, 'services_by_location_page');