import React from 'react';
import { ServiceDetail } from '@/types/service';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/lib/supabase';
import { Review } from '@/lib/supabase/reviews';
import { Skeleton } from '@/components/ui/skeleton';
import { Spinner } from '@/components/ui/spinner';
import { Card } from '@/components/ui/card';
import ReviewCard from '@/components/ui/review-card';

interface ServiceReviewsProps {
  service: ServiceDetail;
  locationName?: string;
}

const ServiceReviews: React.FC<ServiceReviewsProps> = ({ service, locationName }) => {
  // Early return if no review_ids are present
  if (!service.review_ids || service.review_ids.length === 0) {
    return null;
  }

  // Fetch the reviews for this service
  const {
    data: reviews,
    isLoading,
    error,
    isError,
  } = useQuery({
    queryKey: ['service-reviews', service.id, service.review_ids],
    queryFn: async () => {
      if (!service.review_ids || service.review_ids.length === 0) {
        return [];
      }

      try {
        const { data, error } = await supabase
          .from('reviews')
          .select('*')
          .in('id', service.review_ids)
          .order('create_time', { ascending: false });

        if (error) {
          throw error;
        }

        return data as Review[];
      } catch (err) {
        console.error('Error in review fetch function:', err);
        throw err;
      }
    },
    enabled: Array.isArray(service.review_ids) && service.review_ids.length > 0,
  });

  // Loading state
  if (isLoading) {
    return <ReviewsLoadingState locationName={locationName} />;
  }

  // Error state
  if (isError) {
    console.error('Error in ServiceReviews component:', error);
    return null;
  }

  // No reviews state
  if (!reviews || reviews.length === 0) {
    return null;
  }

  return (
    <section className='py-16 bg-gray-50'>
      <div className='container mx-auto px-4'>
        <div className='mb-12 text-center'>
          <h2 className='text-3xl font-bold mb-4'>
            {locationName 
              ? `Customer Reviews from ${locationName}`
              : 'Customer Reviews'}
          </h2>
          <p className='text-gray-600 max-w-2xl mx-auto'>
            Don't just take our word for it. See what our customers 
            {locationName ? ` in ${locationName}` : ''} have to say
            about our {service.title.toLowerCase()} services.
          </p>
        </div>

        {/* Mobile view (stacked cards) */}
        <div className='block md:hidden space-y-6'>
          {reviews.map((review) => (
            <div key={review.id} className='w-full'>
              <ReviewCard
                review={review}
                showLocation={!!review.location_name}
                className='h-full shadow-md'
              />
            </div>
          ))}
        </div>

        {/* Desktop view (grid) */}
        <div className='hidden md:grid md:grid-cols-2 lg:grid-cols-3 gap-6'>
          {reviews.map((review) => (
            <div key={review.id} className='w-full'>
              <ReviewCard
                review={review}
                showLocation={!!review.location_name}
                className='h-full shadow-md'
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

// Loading state component
const ReviewsLoadingState: React.FC<{locationName?: string}> = ({ locationName }) => {
  return (
    <section className='py-16 bg-gray-50'>
      <div className='container mx-auto px-4'>
        <div className='mb-12 text-center'>
          <h2 className='text-3xl font-bold mb-4'>
            {locationName 
              ? `Customer Reviews from ${locationName}`
              : 'Customer Reviews'}
          </h2>
          <p className='text-gray-600 max-w-2xl mx-auto'>
            Don't just take our word for it. See what our customers have to say
            about our services.
          </p>
        </div>

        <div className='flex justify-center items-center mb-8'>
          <Spinner className='text-[#D00B0E] mr-2' />
          <p className='text-gray-600'>Loading reviews...</p>
        </div>

        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
          {[1, 2, 3].map((_, index) => (
            <Card key={index} className='overflow-hidden h-full flex flex-col'>
              <div className='p-6 flex flex-col h-full'>
                <div className='flex items-start justify-between mb-4'>
                  <div className='flex items-center'>
                    <Skeleton className='h-10 w-10 rounded-full mr-3' />
                    <div>
                      <Skeleton className='h-4 w-24 mb-1' />
                      <Skeleton className='h-3 w-16' />
                    </div>
                  </div>
                </div>
                <Skeleton className='h-4 w-full mb-2' />
                <Skeleton className='h-4 w-full mb-2' />
                <Skeleton className='h-4 w-3/4' />
              </div>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServiceReviews;
