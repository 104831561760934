import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import AdminLayout from '@/components/admin/AdminLayout';
import { ADMIN_ROUTES } from '@/routes';
import {
  fetchLocationServices,
  deleteLocationService,
} from '@/lib/supabase/location-services';
import { fetchLocations } from '@/lib/supabase/locations';
import { fetchServices } from '@/lib/supabase/services';
import { LocationService } from '@/types/location-service';
import { Location } from '@/types/location';
import { ServiceDetail } from '@/types/service';

const AdminLocationServices: React.FC = () => {
  const [locationFilter, setLocationFilter] = useState<string>('');
  const [serviceFilter, setServiceFilter] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedLocationService, setSelectedLocationService] =
    useState<LocationService | null>(null);
  const queryClient = useQueryClient();

  // Fetch location services - wrapper for React Query compatibility
  const {
    data: locationServicesData,
    isLoading: isLoadingLocationServices,
    error: locationServicesError,
  } = useQuery({
    queryKey: ['locationServices'],
    queryFn: async () => {
      return await fetchLocationServices();
    },
  });
  const locationServices = locationServicesData as
    | LocationService[]
    | undefined;

  // Fetch locations for filter - wrapper for React Query compatibility
  const {
    data: locationsData,
    isLoading: isLoadingLocations,
    error: locationsError,
  } = useQuery({
    queryKey: ['locations'],
    queryFn: async () => {
      return await fetchLocations();
    },
  });
  const locations = locationsData as Location[] | undefined;

  // Fetch services for filter - wrapper for React Query compatibility
  const {
    data: servicesData,
    isLoading: isLoadingServices,
    error: servicesError,
  } = useQuery({
    queryKey: ['services'],
    queryFn: async () => {
      return await fetchServices();
    },
  });
  const services = servicesData as ServiceDetail[] | undefined;

  // Delete mutation
  const deleteMutation = useMutation({
    mutationFn: async (id: string) => {
      return await deleteLocationService(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['locationServices'] });
      toast.success('Location service deleted successfully');
      setIsDeleteDialogOpen(false);
      setSelectedLocationService(null);
    },
    onError: (error: Error) => {
      console.error('Error deleting location service:', error);
      toast.error('Failed to delete location service');
    },
  });

  const handleDelete = (id: string) => {
    if (
      window.confirm('Are you sure you want to delete this location service?')
    ) {
      deleteMutation.mutate(id);
    }
  };

  // Check for any errors
  if (locationServicesError || locationsError || servicesError) {
    return (
      <AdminLayout title='Location Services'>
        <div className='p-4'>
          <div
            className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative'
            role='alert'>
            <strong className='font-bold'>Error:</strong>
            <span className='block sm:inline'>
              {' '}
              Failed to load data. Please try again later.
            </span>
          </div>
        </div>
      </AdminLayout>
    );
  }

  // Filter location services if available
  const filteredLocationServices =
    locationServices && locationServices.length > 0
      ? locationServices.filter((ls: LocationService) => {
          if (!ls) return false;

          // Handle possible null/undefined values
          const matchesLocation =
            !locationFilter || locationFilter === 'all'
              ? true
              : String(ls.location_id) === locationFilter;

          const matchesService =
            !serviceFilter || serviceFilter === 'all'
              ? true
              : String(ls.service_id) === serviceFilter;

          const matchesSearch = !searchTerm
            ? true
            : ls.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
              ls.description
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              false;

          return matchesLocation && matchesService && matchesSearch;
        })
      : [];

  const isLoading =
    isLoadingLocationServices || isLoadingLocations || isLoadingServices;

  if (isLoading) {
    return (
      <AdminLayout title='Location Services'>
        <div className='p-4'>Loading...</div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout title='Location Services'>
      <div className='p-4'>
        <div className='flex justify-between items-center mb-6'>
          <h1 className='text-2xl font-bold'>Location Services</h1>
          <Link to={ADMIN_ROUTES.EDIT_LOCATION_SERVICE_PATH('new')}>
            <Button>Add New Location Service</Button>
          </Link>
        </div>

        {/* Filters */}
        <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-6'>
          <div>
            <Select value={locationFilter} onValueChange={setLocationFilter}>
              <SelectTrigger>
                <SelectValue placeholder='Filter by Location' />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value='all'>All Locations</SelectItem>
                {locations &&
                  locations.length > 0 &&
                  locations.map((location: Location) => (
                    <SelectItem key={location.id} value={String(location.id)}>
                      {location.name}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
          </div>
          <div>
            <Select value={serviceFilter} onValueChange={setServiceFilter}>
              <SelectTrigger>
                <SelectValue placeholder='Filter by Service' />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value='all'>All Services</SelectItem>
                {services &&
                  services.length > 0 &&
                  services.map((service: ServiceDetail) => (
                    <SelectItem key={service.id} value={String(service.id)}>
                      {service.title}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
          </div>
          <div>
            <Input
              placeholder='Search by title or description'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        {/* Location Services Table */}
        <div className='overflow-x-auto'>
          <table className='w-full border-collapse'>
            <thead>
              <tr className='bg-gray-100'>
                <th className='p-2 text-left'>Title</th>
                <th className='p-2 text-left'>Location</th>
                <th className='p-2 text-left'>Service</th>
                <th className='p-2 text-left'>Slug</th>
                <th className='p-2 text-left'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredLocationServices &&
              filteredLocationServices.length > 0 ? (
                filteredLocationServices.map((ls: LocationService) => {
                  if (!ls) return null;

                  const location =
                    locations && locations.length > 0
                      ? locations.find(
                          (l: Location) =>
                            l && String(l.id) === String(ls.location_id)
                        )
                      : undefined;

                  const service =
                    services && services.length > 0
                      ? services.find(
                          (s: ServiceDetail) =>
                            s && String(s.id) === String(ls.service_id)
                        )
                      : undefined;

                  return (
                    <tr key={ls.id} className='border-b'>
                      <td className='p-2'>{ls.title}</td>
                      <td className='p-2'>{location?.name || 'Unknown'}</td>
                      <td className='p-2'>{service?.title || 'Unknown'}</td>
                      <td className='p-2'>{ls.slug}</td>
                      <td className='p-2 flex space-x-2'>
                        <Link
                          to={ADMIN_ROUTES.EDIT_LOCATION_SERVICE_PATH(ls.id)}>
                          <Button variant='outline' size='sm'>
                            Edit
                          </Button>
                        </Link>
                        <Button
                          variant='destructive'
                          size='sm'
                          onClick={() => handleDelete(ls.id)}>
                          Delete
                        </Button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={5} className='p-2 text-center'>
                    No location services found.{' '}
                    {locationFilter || serviceFilter || searchTerm
                      ? 'Try adjusting your filters.'
                      : ''}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminLocationServices;
