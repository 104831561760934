
import { supabase } from '@/lib/supabase/client';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'sonner';

// Constants
export const STORAGE_BUCKET = 'site-assets';
export const SERVICE_IMAGES_BUCKET = 'service_images';
export const CONTACT_UPLOADS_BUCKET = 'contact-uploads';
export const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

export interface FileUploadOptions {
  /**
   * The storage bucket to upload to
   * @default 'site-assets'
   */
  bucket?: string;
  
  /**
   * Maximum file size in bytes
   * @default 5242880 (5MB)
   */
  maxSize?: number;
  
  /**
   * Prefix for the file name
   * @default ''
   */
  prefix?: string;
  
  /**
   * Whether to use timestamp instead of UUID for uniqueness
   * @default false
   */
  useTimestamp?: boolean;
  
  /**
   * Folder path within bucket
   * @default ''
   */
  folderPath?: string;
}

/**
 * Uploads a file to Supabase storage and returns the public URL
 */
export async function uploadFile(
  file: File, 
  options: FileUploadOptions = {}
): Promise<{ url: string | null; error: string | null }> {
  const { 
    bucket = STORAGE_BUCKET,
    maxSize = MAX_FILE_SIZE,
    prefix = '',
    useTimestamp = false,
    folderPath = ''
  } = options;
  
  try {
    // Validate file size
    if (file.size > maxSize) {
      const errorMsg = `File is too large. Maximum size is ${maxSize / (1024 * 1024)}MB.`;
      toast.error(errorMsg);
      return { url: null, error: errorMsg };
    }
    
    // Generate a unique filename
    const uniqueId = useTimestamp ? Date.now() : uuidv4();
    const fileExt = file.name.split('.').pop();
    const fileName = `${prefix}${prefix ? '-' : ''}${uniqueId}.${fileExt}`;
    const filePath = folderPath ? `${folderPath}/${fileName}` : fileName;
    
    console.log(`Uploading file ${file.name} to ${bucket}/${filePath}`);
    
    // Upload to storage
    const { error: uploadError } = await supabase.storage
      .from(bucket)
      .upload(filePath, file, {
        cacheControl: '3600',
        upsert: false
      });

    if (uploadError) {
      console.error('Error uploading file:', uploadError);
      return { url: null, error: `Upload failed: ${uploadError.message || 'Unknown error'}` };
    }

    // Get public URL
    const { data: publicUrlData } = supabase.storage
      .from(bucket)
      .getPublicUrl(filePath);

    if (publicUrlData) {
      return { url: publicUrlData.publicUrl, error: null };
    }
    
    return { url: null, error: 'Failed to get public URL' };
  } catch (error) {
    console.error("Error uploading file:", error);
    toast.error('File upload failed. Please try again.');
    return { url: null, error: error instanceof Error ? error.message : 'Unknown error' };
  }
}

/**
 * Deletes a file from Supabase storage by URL
 */
export async function deleteFileByUrl(
  url: string, 
  bucket: string = STORAGE_BUCKET
): Promise<boolean> {
  try {
    // Check if the URL is from our storage
    if (!url.includes(bucket)) {
      return false;
    }
    
    // Extract the filename from the URL
    const urlParts = url.split('/');
    const fileName = urlParts[urlParts.length - 1];
    
    if (!fileName) {
      return false;
    }
    
    // Delete from storage
    const { error } = await supabase.storage
      .from(bucket)
      .remove([fileName]);
      
    if (error) {
      console.warn('Failed to delete file from storage:', error);
      return false;
    }
    
    return true;
  } catch (error) {
    console.error('Error deleting file:', error);
    return false;
  }
}

// Legacy function for backward compatibility
export const uploadServiceImage = async (file: File): Promise<string | null> => {
  try {
    const result = await uploadFile(file, { 
      bucket: SERVICE_IMAGES_BUCKET,
      prefix: 'service'
    });
    return result.url;
  } catch (error) {
    console.error('Error in uploadServiceImage:', error);
    return null;
  }
};

/**
 * Upload a file for contact form attachments
 */
export const uploadContactFormFile = async (file: File): Promise<{ url: string | null; error: string | null }> => {
  try {
    return await uploadFile(file, {
      bucket: CONTACT_UPLOADS_BUCKET,
      prefix: 'contact',
      folderPath: 'contact-form',
      maxSize: 15 * 1024 * 1024 // 15MB limit for contact form uploads
    });
  } catch (error) {
    console.error('Error in uploadContactFormFile:', error);
    return { url: null, error: error instanceof Error ? error.message : 'Unknown error' };
  }
};
