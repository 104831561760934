import { SchemaContext } from './baseSchema';

/**
 * FAQ item interface for consistent typing
 */
export interface FAQItem {
  question: string;
  answer: string;
}

/**
 * Generate FAQ Page schema for a list of FAQ items
 * @param faqItems Array of FAQ items with question and answer properties
 * @param context Schema context
 * @param mainEntity Optional parent entity to attach FAQs to
 * @returns Schema.org FAQ Page JSON-LD object
 */
export const getFAQSchema = (
  faqItems: FAQItem[],
  context: SchemaContext,
  mainEntity?: any
) => {
  // Return null if no FAQ items
  if (!faqItems || faqItems.length === 0) return null;

  // Create the FAQ schema
  const faqSchema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqItems.map((item) => ({
      '@type': 'Question',
      name: item.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: item.answer,
      },
    })),
  };

  return faqSchema;
};

/**
 * Generate FAQ items for inclusion within another schema (not as standalone FAQPage)
 * Useful when adding FAQs to a Service or LocalBusiness schema
 * @param faqItems Array of FAQ items with question and answer properties
 * @returns Array of Question schema objects
 */
export const getFAQItemsForSchema = (faqItems: FAQItem[]) => {
  // Return empty array if no FAQ items
  if (!faqItems || faqItems.length === 0) return [];

  // Create array of Question objects for embedding in another schema
  return faqItems.map((item) => ({
    '@type': 'Question',
    name: item.question,
    acceptedAnswer: {
      '@type': 'Answer',
      text: item.answer,
    },
  }));
};
