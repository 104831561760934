
import React from 'react';
import { Button } from '@/components/ui/button';
import { CheckCircle2, RefreshCcw, AlertTriangle } from 'lucide-react';

interface SubmissionStatusProps {
  state: 'submitting' | 'success' | 'error';
  clientName?: string;
  leadId?: string | null;
  error?: string | null;
  onRetry?: () => void;
  onReset?: () => void;
}

const SubmissionStatus: React.FC<SubmissionStatusProps> = ({
  state,
  clientName,
  leadId,
  error,
  onRetry,
  onReset
}) => {
  if (state === 'submitting') {
    return (
      <div className="flex flex-col items-center justify-center py-12">
        <div className="mb-4">
          <svg className="animate-spin h-12 w-12 text-[#D00B0E]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
        <h3 className="text-xl font-semibold mb-2">Submitting Your Request</h3>
        <p className="text-gray-500 text-center max-w-md">
          Please wait while we process your request. This will only take a moment.
        </p>
      </div>
    );
  }

  if (state === 'success') {
    return (
      <div className="flex flex-col items-center justify-center py-12">
        <div className="mb-4">
          <CheckCircle2 className="h-16 w-16 text-green-500" />
        </div>
        <h3 className="text-xl font-semibold mb-2">
          {clientName ? `Thank you, ${clientName.split(' ')[0]}!` : 'Thank You!'}
        </h3>
        <p className="text-gray-500 text-center max-w-md mb-6">
          Your message has been sent successfully. We'll get back to you as soon as possible.
          {leadId && <span className="block text-sm mt-2">Reference ID: {leadId}</span>}
        </p>
        {onReset && (
          <Button onClick={onReset}>
            Submit Another Request
          </Button>
        )}
      </div>
    );
  }

  if (state === 'error') {
    return (
      <div className="flex flex-col items-center justify-center py-12">
        <div className="mb-4">
          <AlertTriangle className="h-16 w-16 text-amber-500" />
        </div>
        <h3 className="text-xl font-semibold mb-2">Something Went Wrong</h3>
        <p className="text-gray-500 text-center max-w-md mb-6">
          We couldn't process your request at this time.
          {error && <span className="block text-sm mt-2 text-red-500">{error}</span>}
        </p>
        {onRetry && (
          <div className="space-x-4">
            <Button onClick={onRetry} className="bg-[#D00B0E] hover:bg-[#B00A0C]">
              <RefreshCcw className="h-4 w-4 mr-2" />
              Try Again
            </Button>
            {onReset && (
              <Button variant="outline" onClick={onReset}>
                Start Over
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default SubmissionStatus;
