
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '@/components/header';
import Footer from '@/components/Footer';
import { fetchServices } from '@/lib/supabase/services';
import { ServiceDetail } from '@/types/service';
import { ArrowRight, MapPin } from 'lucide-react';
import PageMeta from '@/components/SEO/PageMeta';
import { Button } from '@/components/ui/button';

const ServicesHub: React.FC = () => {
  const [services, setServices] = useState<ServiceDetail[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadServices() {
      try {
        setLoading(true);
        const servicesData = await fetchServices();
        setServices(servicesData);
      } catch (error) {
        console.error("Error loading services:", error);
      } finally {
        setLoading(false);
      }
    }

    loadServices();
  }, []);

  // Create schema.org structured data for service list
  const servicesSchema = {
    "@type": "Organization",
    "name": "Fix My Brick",
    "hasOfferCatalog": {
      "@type": "OfferCatalog",
      "name": "Masonry Services",
      "itemListElement": services.map(service => ({
        "@type": "Offer",
        "itemOffered": {
          "@type": "Service",
          "name": service.title,
          "description": service.description
        }
      }))
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <PageMeta 
        title="Masonry Services"
        description="We provide a comprehensive range of masonry services to maintain, repair, and enhance the beauty and structural integrity of your property."
        schema={servicesSchema}
      />
      <Header />
      <main className="flex-grow">
        <section className="bg-gray-50 py-16">
          <div className="container mx-auto px-4">
            <div className="text-center mb-12">
              <h1 className="text-4xl md:text-5xl font-bold mb-4">Our Masonry Services</h1>
              <p className="text-lg text-gray-600 max-w-3xl mx-auto mb-6">
                We provide a comprehensive range of masonry services to maintain, repair, and enhance the beauty and structural integrity of your property.
              </p>
              
              <Link 
                to="/services-by-location" 
                className="inline-flex items-center bg-[#D00B0E]/10 hover:bg-[#D00B0E]/20 text-[#D00B0E] px-5 py-2 rounded-md text-sm font-medium transition-colors"
              >
                <MapPin className="mr-2 h-4 w-4" />
                Find services by location
              </Link>
            </div>

            {loading ? (
              <div className="flex justify-center items-center py-20">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-brick"></div>
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {services.map((service) => (
                  <div key={service.id} className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col h-full">
                    {service.image_url && (
                      <div className="h-48 overflow-hidden">
                        <img 
                          src={service.image_url} 
                          alt={service.title} 
                          className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
                        />
                      </div>
                    )}
                    <div className="p-6 flex flex-col flex-grow">
                      <h2 className="text-2xl font-bold mb-2">{service.title}</h2>
                      <p className="text-gray-600 mb-4 flex-grow">{service.description}</p>
                      <Button 
                        variant="outline"
                        className="text-brick hover:text-white hover:bg-brick border-brick w-full mt-2 h-12"
                        asChild
                      >
                        <Link to={`/services/${service.slug}`} className="inline-flex items-center justify-center">
                          Learn more <ArrowRight className="ml-2 h-4 w-4" />
                        </Link>
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default ServicesHub;
