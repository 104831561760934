import { useServiceData } from './useServiceData';
import { useServiceSave } from './useServiceSave';
import { useServiceFields } from './useServiceFields';
import { useServiceQuery } from './useServiceQuery';

/**
 * Main hook for service form functionality.
 * Combines data fetching, saving, and field management.
 */
export const useServiceForm = (id?: string) => {
  // Handle both /new and /edit/:id paths
  // For /edit/:id, the id parameter will be the actual ID
  // For /new, the id parameter will be 'new'
  const serviceId = id === 'new' ? undefined : id;

  const { formData, setFormData, loading, isNewService } =
    useServiceData(serviceId);
  const { saving, handleSave } = useServiceSave(
    formData,
    isNewService,
    serviceId
  );
  const {
    handleChange,
    addBenefit,
    removeBenefit,
    updateBenefit,
    addProcessStep,
    removeProcessStep,
    updateProcessStep,
    addFAQ,
    removeFAQ,
    updateFAQ,
    addGalleryImage,
    removeGalleryImage,
    updateGalleryImageCaption,
    updateGalleryImageUrl,
  } = useServiceFields(formData, setFormData);

  return {
    formData,
    loading,
    saving,
    isNewService,
    handleChange,
    handleSave,
    addBenefit,
    removeBenefit,
    updateBenefit,
    addProcessStep,
    removeProcessStep,
    updateProcessStep,
    addFAQ,
    removeFAQ,
    updateFAQ,
    addGalleryImage,
    removeGalleryImage,
    updateGalleryImageCaption,
    updateGalleryImageUrl,
  };
};
