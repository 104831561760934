import { useSettings } from '@/hooks/useSettings';

export interface SchemaContext {
  url: string;
  origin: string;
  settings: any;
}

/**
 * Get the base context for schema.org objects
 */
export const getSchemaContext = (settings: any = {}): SchemaContext => {
  // Get the current URL and origin
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const origin = typeof window !== 'undefined' ? window.location.origin : '';

  return {
    url,
    origin,
    settings,
  };
};

/**
 * Generate base Organization schema
 */
export const getOrganizationSchema = (context: SchemaContext) => {
  const { origin, settings } = context;

  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: settings.business_name || 'Fix My Brick',
    url: origin,
    logo: settings.logo_url,
    address: settings.address
      ? {
          '@type': 'PostalAddress',
          streetAddress: settings.address,
        }
      : undefined,
    contactPoint: settings.phone_number
      ? {
          '@type': 'ContactPoint',
          telephone: settings.phone_number,
          contactType: 'customer service',
        }
      : undefined,
  };
};

/**
 * Generate LocalBusiness schema (more specific than Organization)
 */
export const getLocalBusinessSchema = (context: SchemaContext) => {
  const { origin, settings } = context;

  return {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name: settings.business_name || 'Fix My Brick',
    description: 'Professional masonry and brick repair services',
    url: origin,
    logo: settings.logo_url,
    image: settings.home_hero_image || settings.logo_url,
    address: settings.address
      ? {
          '@type': 'PostalAddress',
          streetAddress: settings.address,
        }
      : undefined,
    telephone: settings.phone_number,
    email: settings.email,
    priceRange: '$$',
    areaServed: 'Ontario',
    sameAs: [
      settings.facebook_url,
      settings.instagram_url,
      settings.twitter_url,
    ].filter(Boolean), // Filter out empty values
  };
};

/**
 * Generate BreadcrumbList schema
 */
export const getBreadcrumbsSchema = (
  items: Array<{ path: string; label: string }>,
  origin: string
) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: items.map((item, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@id': `${origin}${item.path}`,
        name: item.label,
      },
    })),
  };
};

/**
 * Helper to safely merge schema objects
 */
export const mergeSchema = (baseSchema: any, customSchema: any) => {
  if (!customSchema) return baseSchema;
  if (!baseSchema) return customSchema;

  // Ensure context is preserved
  const merged = {
    '@context': 'https://schema.org',
    ...baseSchema,
    ...customSchema,
  };

  // Remove undefined values
  Object.keys(merged).forEach(
    (key) => merged[key] === undefined && delete merged[key]
  );

  return merged;
};
