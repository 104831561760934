
import React, { useState, useEffect } from 'react';
import { cn } from '@/lib/utils';
import { Skeleton } from '@/components/ui/skeleton';
import { AlertCircle, Image as ImageIcon } from 'lucide-react';

interface LazyImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  fallback?: React.ReactNode;
  errorFallback?: React.ReactNode;
  fallbackSrc?: string;
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
}

const LazyImage = ({
  src,
  alt = '',
  className,
  fallback,
  errorFallback,
  fallbackSrc,
  objectFit = 'cover',
  ...props
}: LazyImageProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [imgSrc, setImgSrc] = useState<string | undefined>(src);

  useEffect(() => {
    // Reset states when src changes
    setIsLoading(true);
    setHasError(false);
    setImgSrc(src);
  }, [src]);

  const handleLoad = () => {
    setIsLoading(false);
  };

  const handleError = () => {
    setIsLoading(false);
    setHasError(true);
    // If fallbackSrc is provided, use it when there's an error
    if (fallbackSrc) {
      setImgSrc(fallbackSrc);
      setHasError(false); // Reset error state to try loading the fallback
    }
  };

  // Default fallbacks if not provided
  const defaultFallback = fallback || (
    <div className="flex items-center justify-center h-full w-full bg-muted/30">
      <Skeleton className="h-full w-full" />
    </div>
  );

  const defaultErrorFallback = errorFallback || (
    <div className="flex flex-col items-center justify-center h-full w-full bg-muted/10 rounded-md p-2">
      <AlertCircle className="h-6 w-6 text-muted-foreground mb-1" />
      <p className="text-xs text-center text-muted-foreground">Image not available</p>
    </div>
  );

  if (hasError) {
    return (
      <div className={cn("relative overflow-hidden", className)}>
        {defaultErrorFallback}
      </div>
    );
  }

  return (
    <div className={cn("relative overflow-hidden", className)}>
      {isLoading && defaultFallback}
      {imgSrc && (
        <img
          src={imgSrc}
          alt={alt}
          className={cn(
            "transition-opacity duration-300",
            isLoading ? "opacity-0" : "opacity-100",
            `object-${objectFit}`,
            className
          )}
          onLoad={handleLoad}
          onError={handleError}
          {...props}
        />
      )}
    </div>
  );
};

export default LazyImage;
