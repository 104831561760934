
import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Layout } from '@/components/Layout';
import { useLocationServiceQuery } from '@/hooks/useLocationServiceQuery';
import LoadingState from '@/components/service/LoadingState';
import NotFoundState from '@/components/service/NotFoundState';
import ServiceHero from '@/components/service/ServiceHero';
import ServiceDescription from '@/components/service/ServiceDescription';
import ServiceBenefits from '@/components/service/ServiceBenefits';
import ServiceFAQ from '@/components/service/ServiceFAQ';
import ServiceCTA from '@/components/service/ServiceCTA';
import ServiceProcessSteps from '@/components/service/ServiceProcessSteps';
import ServiceGallery from '@/components/service/ServiceGallery';
import PageMeta from '@/components/SEO/PageMeta';
import ServiceReviews from '@/components/service/ServiceReviews';
import Breadcrumbs from '@/components/SEO/Breadcrumbs';
import { useQueryClient } from '@tanstack/react-query';
import {
  useContentTracking,
  useScrollTracking,
} from '@/hooks/useEventTracking';
import { useTrackingParams } from '@/hooks/useTrackingParams';
import { withPageTracking } from '@/components/tracking/TrackingProvider';
import {
  getSchemaContext,
  getServiceSchema,
  getFAQSchema,
  FAQItem,
  getLocationSchema,
} from '@/components/SEO/schema';
import { Helmet } from 'react-helmet-async';

const LocationServiceDetail = () => {
  // Get the location and service slugs from URL parameters
  const { locationSlug, serviceSlug } = useParams<{
    locationSlug: string;
    serviceSlug: string;
  }>();
  const queryClient = useQueryClient();

  // Get tracking parameters
  const trackingParams = useTrackingParams();

  // Use the custom hook to fetch location-specific service data
  const {
    data: locationService,
    isLoading,
    error,
  } = useLocationServiceQuery(locationSlug || '', serviceSlug || '');

  // Initialize tracking hooks
  const { trackServiceView } = useContentTracking();

  // Initialize scroll tracking with predefined thresholds
  useScrollTracking([25, 50, 75, 90]);

  // Track service view when location service loads
  useEffect(() => {
    if (locationService) {
      // Track service view with tracking parameters
      trackServiceView(
        String(locationService.id),
        locationService.title,
        {
          service_slug: locationService.slug,
          location_slug: locationSlug,
          service_type: locationService.services?.title || '',
          has_gallery: !!locationService.gallery_images?.length,
          has_reviews: !!service.review_ids?.length,
          is_featured: locationService.is_featured || false,
          ...trackingParams,
        }
      );
    }
  }, [locationService, trackServiceView, trackingParams, locationSlug]);

  // Show loading state while fetching
  if (isLoading) {
    return <LoadingState />;
  }

  // Show not found state if location service doesn't exist or there was an error
  if (error || !locationService) {
    return <NotFoundState />;
  }

  // Use the location and service data from the joined query
  const location = locationService.locations;
  const service = locationService.services;

  if (!location || !service) {
    return <NotFoundState />;
  }

  // Generate schema data for the location-specific service
  const schemaContext = getSchemaContext();

  // Get service schema
  const serviceSchema = getServiceSchema(
    {
      ...service,
      title: locationService.title,
      description: locationService.description || service.description,
      full_description: locationService.full_description || service.full_description,
      meta_title: locationService.meta_title,
      meta_description: locationService.meta_description,
      benefits: locationService.benefits || service.benefits,
      process_steps: locationService.process_steps || service.process_steps,
      faq: locationService.faq || service.faq,
    },
    schemaContext
  );

  // Get location schema
  const locationSchema = getLocationSchema(
    location,
    schemaContext,
    null,
    []
  );

  // Combine schemas
  const combinedSchema = {
    ...serviceSchema,
    location: locationSchema.location,
  };

  // If there are FAQs, also generate FAQ schema
  let schemaToUse = combinedSchema;
  if (locationService.faq && locationService.faq.length > 0) {
    // Create a separate FAQ schema using our utility
    const faqSchema = getFAQSchema(locationService.faq as FAQItem[], schemaContext);

    // Use the combined schema as our primary schema
    schemaToUse = combinedSchema;
  }

  return (
    <Layout>
      <PageMeta
        title={
          locationService.meta_title ||
          `${locationService.title} in ${location.name} | Fix My Brick`
        }
        description={
          locationService.meta_description ||
          `Professional ${service.title.toLowerCase()} services in ${location.name}. Expert masonry solutions by Fix My Brick.`
        }
        canonical={
          locationService.canonical_url ||
          `/locations/${locationSlug}/services/${serviceSlug}`
        }
        image={locationService.hero_image || service.image_url}
        type='website'
        schema={schemaToUse}
      />

      {/* Add FAQ schema separately if it exists */}
      {locationService.faq && locationService.faq.length > 0 && (
        <Helmet>
          <script type='application/ld+json'>
            {JSON.stringify(
              getFAQSchema(locationService.faq as FAQItem[], schemaContext)
            )}
          </script>
          
          {/* Add additional SEO meta tags */}
          <meta name='geo.region' content='CA-ON' />
          <meta name='geo.placename' content={location.name} />
          {location.latitude && location.longitude && (
            <>
              <meta name='geo.position' content={`${location.latitude};${location.longitude}`} />
              <meta name='ICBM' content={`${location.latitude}, ${location.longitude}`} />
            </>
          )}
          <link rel='alternate' href={window.location.href} hrefLang='en-ca' />
        </Helmet>
      )}

      <div className='container mx-auto px-4'>
        <Breadcrumbs
          items={[
            { label: 'Locations', path: '/locations' },
            { label: location.name, path: `/locations/${locationSlug}` },
            { label: 'Services', path: `/locations/${locationSlug}#services` },
            {
              label: locationService.title,
              path: `/locations/${locationSlug}/services/${serviceSlug}`,
            },
          ]}
          className='py-4'
        />
      </div>

      {/* Use the existing service components but with location-specific data */}
      <ServiceHero
        service={{
          ...service,
          title: locationService.title,
          description: locationService.description || service.description,
          hero_image: locationService.hero_image || service.hero_image,
          hero_headline: `${service.title} in ${location.name}`,
        }}
      />

      <ServiceReviews service={service} locationName={location.name} />

      <ServiceDescription
        service={{
          ...service,
          full_description: locationService.full_description || service.full_description,
        }}
      />

      {locationService.benefits && locationService.benefits.length > 0 && (
        <ServiceBenefits
          service={{
            ...service,
            benefits: Array.isArray(locationService.benefits) ? locationService.benefits : [],
          }}
        />
      )}

      {locationService.process_steps && locationService.process_steps.length > 0 && (
        <ServiceProcessSteps
          service={{
            ...service,
            process_steps: locationService.process_steps,
          }}
        />
      )}

      {locationService.gallery_images && locationService.gallery_images.length > 0 && (
        <ServiceGallery
          service={{
            ...service,
            gallery_images: locationService.gallery_images,
          }}
        />
      )}

      {locationService.faq && locationService.faq.length > 0 && (
        <ServiceFAQ
          service={{
            ...service,
            faq: locationService.faq,
          }}
        />
      )}

      <ServiceCTA
        service={{
          ...service,
          cta_text: `Need ${service.title.toLowerCase()} in ${location.name}? Contact us today for a free consultation and quote.`,
        }}
      />

      {/* Add a section linking to other services in this location */}
      <div className='bg-gray-50 py-12'>
        <div className='container mx-auto px-4'>
          <h2 className='text-2xl font-bold text-center mb-8'>
            Other Masonry Services in {location.name}
          </h2>
          <div className='flex flex-wrap justify-center gap-4'>
            <Link
              to={`/locations/${locationSlug}`}
              className='bg-white shadow-md rounded-md px-6 py-3 text-gray-800 hover:bg-gray-100 transition-colors'>
              All {location.name} Services
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

// Wrap with page tracking HOC
export default withPageTracking(LocationServiceDetail, 'location_service_page');
