import React from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { PlusCircle, X } from 'lucide-react';

interface GalleryFormProps {
  galleryImages?: {
    id: string;
    image_url: string;
    caption?: string;
  }[];
  addGalleryImage: () => void;
  removeGalleryImage: (index: number) => void;
  updateGalleryImageCaption: (index: number, value: string) => void;
  updateGalleryImageUrl?: (index: number, value: string) => void;
}

const GalleryForm: React.FC<GalleryFormProps> = ({
  galleryImages = [],
  addGalleryImage,
  removeGalleryImage,
  updateGalleryImageCaption,
  updateGalleryImageUrl,
}) => {
  return (
    <div className='space-y-4'>
      <div className='flex items-center justify-between'>
        <Label>Gallery Images</Label>
        <Button
          type='button'
          variant='outline'
          size='sm'
          onClick={addGalleryImage}
          className='flex items-center gap-1'>
          <PlusCircle className='w-4 h-4' />
          Add Image
        </Button>
      </div>

      {galleryImages &&
        galleryImages.map((image, index) => (
          <div key={index} className='p-4 border rounded-md space-y-4'>
            <div className='flex items-center justify-between'>
              <Label className='text-base font-medium'>Image {index + 1}</Label>
              <Button
                type='button'
                variant='ghost'
                size='icon'
                onClick={() => removeGalleryImage(index)}
                className='flex-shrink-0'>
                <X className='w-4 h-4' />
              </Button>
            </div>

            <div className='space-y-2'>
              <Label htmlFor={`gallery-image-url-${index}`}>Image URL</Label>
              <Input
                id={`gallery-image-url-${index}`}
                value={image.image_url}
                onChange={
                  updateGalleryImageUrl
                    ? (e) => updateGalleryImageUrl(index, e.target.value)
                    : undefined
                }
                className='bg-gray-50'
              />
            </div>

            <div className='space-y-2'>
              <Label htmlFor={`gallery-image-caption-${index}`}>Caption</Label>
              <Input
                id={`gallery-image-caption-${index}`}
                value={image.caption || ''}
                onChange={(e) =>
                  updateGalleryImageCaption(index, e.target.value)
                }
                placeholder='Image caption'
              />
            </div>

            {image.image_url && (
              <div className='mt-2'>
                <img
                  src={image.image_url}
                  alt={image.caption || `Gallery image ${index + 1}`}
                  className='max-h-40 rounded border object-cover'
                />
              </div>
            )}
          </div>
        ))}

      {(!galleryImages || galleryImages.length === 0) && (
        <p className='text-sm text-gray-500'>No gallery images added yet.</p>
      )}
    </div>
  );
};

export default GalleryForm;
