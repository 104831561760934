
import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { supabase } from '@/lib/supabase';
import { Dialog, DialogTrigger } from '@/components/ui/dialog';
import { toast } from 'sonner';
import ImagePreview from './ImagePreview';
import UploadDialog from './UploadDialog';
import { compressImage } from '@/utils/imageCompression';

interface ImageUploaderProps {
  currentImageUrl: string;
  onImageUploaded: (url: string) => void;
}

const ImageUploader = ({
  currentImageUrl,
  onImageUploaded,
}: ImageUploaderProps) => {
  const [uploading, setUploading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(
    currentImageUrl || null
  );
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const file = e.target.files[0];
    const fileSize = file.size / 1024 / 1024; // size in MB

    if (fileSize > 5) {
      toast.error('File size exceeds 5MB limit');
      return;
    }

    try {
      setUploading(true);

      // Create a temporary preview
      const objectUrl = URL.createObjectURL(file);
      setPreviewUrl(objectUrl);

      // Compress the image before upload
      const compressedFile = await compressImage(file, {
        maxSizeMB: 2, // More conservative than the 5MB limit
        maxWidthOrHeight: 1920, // Full HD resolution
        useWebWorker: true,
      });

      // Upload file to Supabase storage
      const fileExt = compressedFile.name.split('.').pop();
      const fileName = `${Math.random()
        .toString(36)
        .substring(2, 15)}.${fileExt}`;
      const filePath = `gallery-covers/${fileName}`;

      const { data, error } = await supabase.storage
        .from('gallery-images')
        .upload(filePath, compressedFile);

      if (error) {
        throw error;
      }

      // Get public URL
      const { data: publicUrlData } = supabase.storage
        .from('gallery-images')
        .getPublicUrl(filePath);

      if (publicUrlData) {
        onImageUploaded(publicUrlData.publicUrl);
        toast.success('Image uploaded successfully');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Error uploading image');
      // Revert to previous image if failed
      setPreviewUrl(currentImageUrl);
    } finally {
      setUploading(false);
      setDialogOpen(false);
    }
  };

  return (
    <div className='space-y-4'>
      <div className='flex justify-between items-center'>
        <Label htmlFor='cover_image'>Cover Image</Label>
        <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
          <DialogTrigger asChild>
            <Button variant='outline' size='sm'>
              {currentImageUrl ? 'Change Image' : 'Upload Image'}
            </Button>
          </DialogTrigger>
          <UploadDialog onFileChange={handleFileChange} uploading={uploading} onClose={() => setDialogOpen(false)} />
        </Dialog>
      </div>

      <div className='border rounded-md overflow-hidden bg-muted/20'>
        <ImagePreview uploading={uploading} previewUrl={previewUrl} />
      </div>
    </div>
  );
};

export default ImageUploader;
