import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import { Card, CardContent } from '@/components/ui/card';
import { Location } from '@/types/location';
import { ServiceDetail } from '@/types/service';
import { LocationService } from '@/types/location-service';
import { PUBLIC_ROUTES } from '@/routes';

interface LocationSpecificServiceCardProps {
  service: ServiceDetail;
  location: Location;
  locationService?: LocationService;
}

const LocationSpecificServiceCard: React.FC<LocationSpecificServiceCardProps> = ({ 
  service, 
  location, 
  locationService 
}) => {
  // Determine the URL based on whether there's a location-specific service
  const serviceUrl = locationService 
    ? PUBLIC_ROUTES.LOCATION_SERVICE_DETAIL_PATH(location.slug, service.slug)
    : PUBLIC_ROUTES.SERVICE_DETAIL_PATH(service.slug);
    
  // Use location-specific content when available, or fallback to general service content
  const title = locationService?.title || `${service.title} in ${location.name}`;
  const description = locationService?.description || service.description;
  const imageUrl = locationService?.hero_image || service.image_url;

  return (
    <Card className="overflow-hidden flex flex-col h-full border border-gray-100 shadow-sm relative">
      {/* Location-specific badge */}
      {locationService && (
        <div className="absolute top-2 right-2 z-10">
          <span className="bg-[#D00B0E]/10 text-[#D00B0E] text-xs font-medium px-2 py-1 rounded-full">
            {location.name} Specific
          </span>
        </div>
      )}
      
      {/* Service image */}
      {imageUrl && (
        <div className="h-48 overflow-hidden">
          <img 
            src={imageUrl} 
            alt={title} 
            className="w-full h-full object-cover"
          />
        </div>
      )}
      
      {/* Content section */}
      <CardContent className="p-6 flex flex-col flex-grow">
        <h3 className="text-xl font-semibold mb-3">{title}</h3>
        <p className="text-gray-600 mb-4 flex-grow">{description}</p>
        <Link 
          to={serviceUrl}
          className="text-[#D00B0E] hover:underline flex items-center mt-2"
        >
          Learn more <ArrowRight className="h-4 w-4 ml-1" />
        </Link>
      </CardContent>
    </Card>
  );
};

export default LocationSpecificServiceCard;