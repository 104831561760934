
import React from 'react';
import { CheckCircle } from 'lucide-react';
import { ServiceDetail } from '@/types/service';

interface ServiceBenefitsProps {
  service: ServiceDetail;
}

const ServiceBenefits: React.FC<ServiceBenefitsProps> = ({ service }) => {
  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl md:text-3xl font-bold mb-10 text-center">Benefits of Professional {service.title}</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {service.benefits.map((benefit, index) => {
            // Handle both string benefits and object benefits
            const benefitContent = typeof benefit === 'string' 
              ? benefit 
              : benefit.title && benefit.description 
                ? (
                  <>
                    <h3 className="font-semibold text-gray-800 mb-1">{benefit.title}</h3>
                    <p className="text-gray-700 leading-relaxed">{benefit.description}</p>
                  </>
                ) 
                : benefit.description || benefit.title || '';
            
            return (
              <div 
                key={index} 
                className="bg-white p-6 rounded-xl shadow-md transition-transform duration-300 hover:shadow-lg hover:-translate-y-1"
              >
                <div className="flex items-start">
                  <CheckCircle className="h-6 w-6 text-brick flex-shrink-0 mr-4 mt-1" />
                  <div className="text-gray-700 leading-relaxed">{benefitContent}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ServiceBenefits;
