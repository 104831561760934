
import { useState, useEffect } from 'react';

export interface FormData {
  name: string;
  email: string;
  phone: string;
  address: string;
}

export interface UseFormPersistenceProps {
  storageKey?: string;
}

export const useFormPersistence = ({ storageKey = 'contact-form-data' }: UseFormPersistenceProps = {}) => {
  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientPhone, setClientPhone] = useState('');
  const [clientAddress, setClientAddress] = useState('');

  // Load saved form data on mount
  useEffect(() => {
    try {
      const savedData = localStorage.getItem(storageKey);
      if (savedData) {
        const { name, email, phone, address } = JSON.parse(savedData);
        if (name) setClientName(name);
        if (email) setClientEmail(email);
        if (phone) setClientPhone(phone);
        if (address) setClientAddress(address);
      }
    } catch (error) {
      console.error('Error restoring saved form data:', error);
    }
  }, [storageKey]);

  // Save form data to localStorage
  const saveFormData = (data: FormData) => {
    try {
      localStorage.setItem(storageKey, JSON.stringify(data));
    } catch (err) {
      console.warn('Could not save form data to localStorage');
    }
  };

  return {
    clientName,
    clientEmail,
    clientPhone,
    clientAddress,
    setClientName,
    setClientEmail,
    setClientPhone,
    setClientAddress,
    saveFormData
  };
};
