import {
  SchemaContext,
  getOrganizationSchema,
  getLocalBusinessSchema,
  mergeSchema,
} from './baseSchema';
import {
  isPrimaryLocation,
  formatAddress,
  getGeoCoordinates,
  getAreaServed,
} from './locationTypeHelper';

/**
 * Generate schema for a location page
 * @param location The location data
 * @param context Schema context with URL and settings
 * @param parentLocation Optional parent location
 * @param childLocations Optional array of child locations
 * @returns Schema.org JSON-LD object
 */
export const getLocationSchema = (
  location: any,
  context: SchemaContext,
  parentLocation?: any,
  childLocations?: any[]
) => {
  const { url, origin, settings } = context;

  // Skip if no location data
  if (!location) return null;

  // Base organization schema
  const orgSchema = getOrganizationSchema(context);

  // Determine if this is a primary or secondary location
  const isPrimary = isPrimaryLocation(location);

  if (isPrimary) {
    // For primary locations with physical address, use LocalBusiness schema
    const address = formatAddress(location);
    const geo = getGeoCoordinates(location);
    const areaServed = getAreaServed(location);

    // Create LocalBusiness schema
    const locationSchema = {
      '@type': 'LocalBusiness',
      '@id': `${origin}/locations/${location.slug}`,
      name: `${settings.business_name || 'Fix My Brick'} - ${location.name}`,
      description:
        location.description ||
        `Professional masonry services in ${location.name}`,
      url: url,
      image:
        location.image_url || settings.default_og_image || settings.logo_url,
      telephone: location.phone_number || settings.phone_number,
      email: location.email || settings.email,
      areaServed: areaServed,
      address: address
        ? {
            '@type': 'PostalAddress',
            streetAddress: address,
          }
        : undefined,
      geo: geo,
    };

    // If there's a parent organization, reference it
    if (parentLocation) {
      locationSchema['parentOrganization'] = {
        '@type': 'Organization',
        '@id': `${origin}/locations/${parentLocation.slug}`,
        name: `${settings.business_name || 'Fix My Brick'} - ${
          parentLocation.name
        }`,
      };
    }

    // If there are child locations, add them as department/branch references
    if (childLocations && childLocations.length > 0) {
      locationSchema['department'] = childLocations.map((child) => ({
        '@type': 'LocalBusiness',
        '@id': `${origin}/locations/${child.slug}`,
        name: `${settings.business_name || 'Fix My Brick'} - ${child.name}`,
      }));
    }

    // Merge the organization schema with the location schema
    // This creates a full LocalBusiness schema that inherits Organization properties
    return mergeSchema(orgSchema, locationSchema);
  } else {
    // For secondary locations (service areas without physical address)
    // use Service schema with area served
    const areaServed = getAreaServed(location);

    const serviceAreaSchema = {
      '@type': 'Service',
      '@id': `${origin}/locations/${location.slug}`,
      name: `${settings.business_name || 'Fix My Brick'} Services in ${
        location.name
      }`,
      description:
        location.description ||
        `Professional masonry services in ${location.name}`,
      url: url,
      provider: {
        '@type': 'Organization',
        '@id': origin,
        name: settings.business_name || 'Fix My Brick',
        // If there's a parent location, use it as the provider
        ...(parentLocation && {
          department: {
            '@type': 'LocalBusiness',
            '@id': `${origin}/locations/${parentLocation.slug}`,
            name: `${settings.business_name || 'Fix My Brick'} - ${
              parentLocation.name
            }`,
          },
        }),
      },
      areaServed: areaServed,
      // Add contact information
      telephone: location.phone_number || settings.phone_number,
      email: location.email || settings.email,
    };

    return serviceAreaSchema;
  }
};
