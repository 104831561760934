import { useQuery } from '@tanstack/react-query';
import {
  fetchLocationServiceBySlug,
  fetchLocationServicesByLocation,
  fetchLocationServicesByService,
} from '@/lib/supabase/location-services';

// Hook to fetch a specific location service by slugs
export function useLocationServiceQuery(locationSlug: string, serviceSlug: string) {
  return useQuery({
    queryKey: ['location-service', locationSlug, serviceSlug],
    queryFn: ({ signal }) => fetchLocationServiceBySlug(locationSlug, serviceSlug, signal),
    enabled: !!locationSlug && !!serviceSlug,
  });
}

// Hook to fetch location services by location
export function useLocationServicesByLocationQuery(locationId: string) {
  return useQuery({
    queryKey: ['location-services', 'by-location', locationId],
    queryFn: ({ signal }) => fetchLocationServicesByLocation(locationId, signal),
    enabled: !!locationId,
  });
}

// Hook to fetch location services by service
export function useLocationServicesByServiceQuery(serviceId: string) {
  return useQuery({
    queryKey: ['location-services', 'by-service', serviceId],
    queryFn: ({ signal }) => fetchLocationServicesByService(serviceId, signal),
    enabled: !!serviceId,
  });
}
