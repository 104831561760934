import React, { useRef, useEffect, useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import {
  motion,
  AnimatePresence,
  PanInfo,
  useReducedMotion,
} from 'framer-motion';
import { X } from 'lucide-react';
import { cn } from '@/lib/utils';

interface FlyUpMenuProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title?: string;
  className?: string;
  allowNestedDialogs?: boolean;
}

const FlyUpMenu: React.FC<FlyUpMenuProps> = ({
  isOpen,
  onClose,
  children,
  title,
  className = '',
  allowNestedDialogs = false,
}) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const prefersReducedMotion = useReducedMotion();
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = useCallback(() => {
    setIsClosing(true);
    // Short timeout to allow animation to play
    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 300);
  }, [onClose]);

  // Close on escape key
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      // Only handle escape if no modals are open
      const modals = document.querySelectorAll('[role="dialog"]');
      // If there are multiple dialogs, this is a nested dialog scenario
      // Don't close FlyUpMenu if there are nested dialogs open
      if (modals.length > 1 && allowNestedDialogs) return;

      if (e.key === 'Escape') {
        handleClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, handleClose, allowNestedDialogs]);

  // Lock body scroll when menu is open
  useEffect(() => {
    const scrollY = window.scrollY;

    if (isOpen) {
      // Store current position and lock scroll
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY}px`;
      document.body.style.width = '100%';
    } else if (document.body.style.position === 'fixed') {
      // Restore scroll position when closed
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      window.scrollTo(0, scrollY);
    }

    return () => {
      // Clean up scroll lock
      if (document.body.style.position === 'fixed') {
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.width = '';
        window.scrollTo(0, scrollY);
      }
    };
  }, [isOpen]);

  // Handle drag to dismiss
  const handleDragEnd = (
    _e: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo
  ) => {
    setIsDragging(false);
    // If dragged down more than 100px, close the menu
    if (info.offset.y > 100) {
      handleClose();
    }
  };

  const handleDragStart = () => {
    setIsDragging(true);
  };

  // Client-side only rendering via Portal
  if (typeof window === 'undefined') {
    return null;
  }

  return createPortal(
    <AnimatePresence>
      {isOpen && (
        <div className='fixed inset-0 z-50 flex items-end justify-center'>
          {/* Backdrop */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: prefersReducedMotion ? 0.1 : 0.2 }}
            className='absolute inset-0 bg-black/50 backdrop-blur-sm'
            aria-hidden='true'
            onClick={handleClose}
          />

          {/* FlyUp Menu */}
          <motion.div
            ref={menuRef}
            initial={{ y: '100%' }}
            animate={{ y: 0 }}
            exit={{ y: '100%' }}
            transition={{
              type: prefersReducedMotion ? 'tween' : 'spring',
              damping: 30,
              stiffness: 300,
              duration: prefersReducedMotion ? 0.2 : 0.3,
            }}
            drag='y'
            dragConstraints={{ top: 0 }}
            dragElastic={0.2}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            className={cn(
              `relative bg-white text-gray-900 rounded-t-2xl shadow-2xl overflow-hidden w-full max-h-[90vh]`,
              className,
              isDragging ? 'transition-none' : ''
            )}
            role='dialog'
            aria-modal='true'
            aria-labelledby={title ? 'flyup-title' : undefined}>
            {/* Drag handle */}
            <div className='w-full flex justify-center py-2 cursor-grab active:cursor-grabbing'>
              <div className='w-12 h-1.5 bg-gray-300 rounded-full'></div>
            </div>

            {/* Close button */}
            <button
              onClick={handleClose}
              className='absolute top-4 right-4 z-10 p-1 rounded-full text-gray-600 hover:text-gray-900 hover:bg-gray-100 transition-colors'
              aria-label='Close menu'>
              <X className='h-6 w-6' />
            </button>

            {/* Title */}
            {title && (
              <div className='px-6 pt-4 pb-4 border-b border-gray-200'>
                <h2
                  id='flyup-title'
                  className='text-2xl font-bold text-center text-brick'>
                  {title}
                </h2>
              </div>
            )}

            {/* Content */}
            <div className='overflow-y-auto overscroll-contain max-h-[75vh] pb-safe'>
              {children}
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>,
    document.body
  );
};

export default FlyUpMenu;
