import { useQuery } from '@tanstack/react-query';
import {
  fetchLocationBySlug,
  fetchChildLocations,
  fetchPrimaryLocations,
  fetchLocations,
} from '@/lib/supabase/locations';
import { Location } from '@/types/location';
import { primaryLocations } from '@/data/primaryLocations';

export const useLocationQuery = (slug: string | undefined) => {
  return useQuery({
    queryKey: ['location', slug],
    queryFn: ({ signal }) => fetchLocationBySlug(slug || '', signal),
    enabled: !!slug,
    placeholderData: slug ? primaryLocations[slug] || null : null,
    retry: (failureCount, error: any) => {
      // More comprehensive check for aborted requests
      if (
        error.name === 'AbortError' ||
        (error instanceof DOMException && error.name === 'AbortError') ||
        error.message?.includes('abort') ||
        error.message?.includes('cancel')
      ) {
        console.log(`Location query for ${slug} aborted - no retry`);
        return false; // Don't retry aborted requests
      }

      // Only retry if we got a 500 error or network error
      if (error.status === 500 || error.message?.includes('network')) {
        return failureCount < 2;
      }
      return false; // Don't retry other errors
    },
    gcTime: 5 * 60 * 1000, // Keep data in cache for 5 minutes
  });
};

export const useChildLocationsQuery = (parentId: string | undefined) => {
  return useQuery({
    queryKey: ['childLocations', parentId],
    queryFn: async ({ signal }) => {
      if (!parentId) return [];
      try {
        // Added UUID validation
        const uuidRegex =
          /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        if (!uuidRegex.test(parentId)) {
          console.log(
            `Invalid UUID format in useChildLocationsQuery: ${parentId}`
          );
          return []; // Return empty array for invalid UUIDs
        }

        return await fetchChildLocations(parentId, signal);
      } catch (error: any) {
        // Handle abort errors silently
        if (
          signal?.aborted ||
          error.name === 'AbortError' ||
          error.message?.includes('abort') ||
          error.message?.includes('cancel')
        ) {
          console.log(`Child locations query aborted for ${parentId}`);
          throw new Error('Request aborted');
        }

        // Log other errors
        console.error(
          `Error fetching child locations for parent ${parentId}:`,
          error
        );
        throw error;
      }
    },
    enabled: !!parentId,
    placeholderData: [],
    retry: (failureCount, error: any) => {
      // More comprehensive check for aborted requests
      if (
        error.name === 'AbortError' ||
        error.message?.includes('abort') ||
        error.message?.includes('cancel')
      ) {
        return false; // Don't retry aborted requests
      }

      // For UUID format errors, don't retry
      if (error.code === '22P02' || error.message?.includes('uuid')) {
        return false;
      }

      // For server errors or network issues, retry up to twice
      if (error.status >= 500 || error.message?.includes('network')) {
        return failureCount < 2;
      }

      return false; // Don't retry other errors
    },
    gcTime: 5 * 60 * 1000, // Keep data in cache for 5 minutes
  });
};

export const usePrimaryLocationsQuery = () => {
  return useQuery({
    queryKey: ['primaryLocations'],
    queryFn: async ({ signal }) => {
      try {
        return await fetchPrimaryLocations(signal);
      } catch (error: any) {
        // Handle abort errors silently
        if (
          signal?.aborted ||
          error.name === 'AbortError' ||
          error.message?.includes('abort') ||
          error.message?.includes('cancel')
        ) {
          console.log('Primary locations query aborted');
          throw new Error('Request aborted');
        }

        console.error('Error fetching primary locations:', error);
        throw error;
      }
    },
    placeholderData: Object.values(primaryLocations),
    retry: (failureCount, error: any) => {
      // More comprehensive check for aborted requests
      if (
        error.name === 'AbortError' ||
        error.message?.includes('abort') ||
        error.message?.includes('cancel')
      ) {
        return false; // Don't retry aborted requests
      }

      // For server errors or network issues, retry up to twice
      if (error.status >= 500 || error.message?.includes('network')) {
        return failureCount < 2;
      }

      return false; // Don't retry other errors
    },
    gcTime: 5 * 60 * 1000, // Keep data in cache for 5 minutes
  });
};

export const useLocationsQuery = () => {
  return useQuery({
    queryKey: ['locations'],
    queryFn: ({ signal }) => fetchLocations(signal),
    retry: (failureCount, error: any) => {
      // Don't retry aborted requests
      if (error.name === 'AbortError' || error.message?.includes('aborted')) {
        return false;
      }
      // Only retry if we got a 500 error or network error
      if (error.status === 500 || error.message?.includes('network')) {
        return failureCount < 2;
      }
      return false; // Don't retry other errors
    },
    gcTime: 5 * 60 * 1000, // Keep data in cache for 5 minutes
  });
};
