
import React from 'react';
import { Button } from '@/components/ui/button';
import { SendHorizonal } from 'lucide-react';

interface SubmitButtonProps {
  isSubmitting: boolean;
  disabled?: boolean;
  text?: string;
  loadingText?: string;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  isSubmitting,
  disabled = false,
  text = 'Submit Request',
  loadingText = 'Submitting...'
}) => {
  return (
    <Button
      type="submit"
      className="w-full bg-[#D00B0E] hover:bg-[#B00A0C] transition-colors py-6"
      disabled={isSubmitting || disabled}
    >
      {isSubmitting ? (
        <span className="flex items-center justify-center">
          <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          {loadingText}
        </span>
      ) : (
        <span className="flex items-center justify-center">
          {text}
          <SendHorizonal className="ml-2 h-5 w-5" />
        </span>
      )}
    </Button>
  );
};

export default SubmitButton;
