
import { useState, useEffect } from 'react';

export interface ServiceOption {
  value: string;
  label: string;
}

export interface UseServiceSelectionProps {
  defaultService?: string;
  predefinedServices?: ServiceOption[];
}

const DEFAULT_SERVICES = [
  { value: 'brick-repair', label: 'Brick Repair' },
  { value: 'tuckpointing', label: 'Tuckpointing' },
  { value: 'chimney-repair', label: 'Chimney Repair' },
  { value: 'stone-masonry', label: 'Stone Masonry' },
  { value: 'concrete-work', label: 'Concrete Work' },
  { value: 'masonry-restoration', label: 'Masonry Restoration' },
  { value: 'other', label: 'Other (Please specify)' }
];

export const useServiceSelection = ({
  defaultService = '',
  predefinedServices = DEFAULT_SERVICES
}: UseServiceSelectionProps = {}) => {
  const [selectedService, setSelectedService] = useState(defaultService);
  const [otherService, setOtherService] = useState('');
  const [showOtherServiceInput, setShowOtherServiceInput] = useState(defaultService === 'other');

  // Update the other service input visibility when the selected service changes
  useEffect(() => {
    setShowOtherServiceInput(selectedService === 'other');
  }, [selectedService]);

  const handleServiceChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newService = e.target.value;
    setSelectedService(newService);
  };
  
  const handleOtherServiceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtherService(e.target.value);
  };

  // Get the final service value (either selected or "other" value)
  const getFinalServiceValue = (): string => {
    return showOtherServiceInput ? otherService : selectedService;
  };

  return {
    selectedService,
    otherService,
    showOtherServiceInput,
    predefinedServices,
    handleServiceChange,
    handleOtherServiceChange,
    getFinalServiceValue
  };
};
