
import { useState } from 'react';
import { submitContactFormWithEdgeFunction } from '@/lib/supabase/contactForm';

export interface SubmitFormData {
  client_name: string;
  client_email: string;
  client_phone: string;
  service_street: string;
  service_requested: string;
  message: string;
  file_uploads: string[] | null;
  form_id: string;
  form_name: string;
  service_slug?: string;
  location_name?: string;
  [key: string]: any;
}

export interface UseFormSubmissionProps {
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
  formId?: string;
  formName?: string;
  serviceSlug?: string;
}

export const useFormSubmission = ({
  onSuccess,
  onError,
  formId = 'contact-form',
  formName = 'Contact Form',
  serviceSlug
}: UseFormSubmissionProps = {}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionState, setSubmissionState] = useState<'idle' | 'submitting' | 'success' | 'error'>('idle');
  const [submissionError, setSubmissionError] = useState<string | null>(null);
  const [leadId, setLeadId] = useState<string | null>(null);
  const [clientName, setClientName] = useState('');

  const submitForm = async (formData: SubmitFormData) => {
    setSubmissionState('submitting');
    setIsSubmitting(true);
    setClientName(formData.client_name);
    
    try {
      // Add standard fields
      const enrichedData = {
        ...formData,
        service_slug: serviceSlug || formData.service_slug,
        requested_on_date: new Date().toISOString(),
        submission_status: 'completed',
        status: 'new',
        created_at: new Date().toISOString(),
        form_id: formData.form_id || formId,
        form_name: formData.form_name || formName
      };
      
      const response = await submitContactFormWithEdgeFunction(enrichedData);
      
      if (response.success) {
        setLeadId(response.leadId || null);
        setSubmissionState('success');
        
        if (onSuccess) {
          onSuccess(response);
        }
      } else {
        setSubmissionError(response.error || 'Something went wrong. Please try again.');
        setSubmissionState('error');
        
        if (onError) {
          onError(response.error);
        }
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      
      setSubmissionError(
        error instanceof Error ? error.message : 'Something went wrong. Please try again.'
      );
      setSubmissionState('error');
      
      if (onError) {
        onError(error);
      }
    } finally {
      setIsSubmitting(false);
    }
  };
  
  const resetForm = () => {
    setIsSubmitting(false);
    setSubmissionState('idle');
    setSubmissionError(null);
    setLeadId(null);
    setClientName('');
  };
  
  return {
    isSubmitting,
    submissionState,
    submissionError,
    leadId,
    clientName,
    submitForm,
    resetForm
  };
};
