
import { toast } from 'sonner';
import { useFileUpload } from './useFileUpload';
import { useServiceSelection } from './useServiceSelection';
import { useFormPersistence } from './useFormPersistence';
import { useFormSubmission } from './useFormSubmission';
import type { ContactFormProps } from '../useContactForm';

export const useContactFormRefactored = ({
  onSuccess,
  onError,
  defaultService = '',
  serviceSlug,
  locationName,
  predefinedServices,
  formId = 'contact-form',
  formName = 'Contact Form',
  hideHeader = false
}: ContactFormProps = {}) => {
  // Use specialized hooks
  const fileUpload = useFileUpload({ maxFiles: 5 });
  const serviceSelection = useServiceSelection({ 
    defaultService, 
    predefinedServices 
  });
  const formPersistence = useFormPersistence();
  const formSubmission = useFormSubmission({
    onSuccess,
    onError,
    formId,
    formName,
    serviceSlug
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    // Check if files are still uploading
    if (!fileUpload.isUploadComplete) {
      toast("Please upload your files before submitting the form", {
        description: "Wait for file uploads to complete"
      });
      return;
    }
    
    const formData = new FormData(e.currentTarget);
    const name = formData.get('name') as string;
    const email = formData.get('email') as string;
    const phone = formData.get('phone') as string;
    const address = formData.get('address') as string;
    const messageText = formData.get('message') as string;
    
    // Validate ONLY name and email
    if (!name || !email) {
      toast("Missing Information", {
        description: "Please fill in name and email"
      });
      return;
    }
    
    // Save form data for next time
    formPersistence.saveFormData({
      name,
      email,
      phone,
      address
    });
    
    // Get final service value
    const finalService = serviceSelection.getFinalServiceValue();
    
    // Prepare data for submission
    const contactData = {
      client_name: name,
      client_email: email,
      client_phone: phone ? phone.replace(/\D/g, '') : null, // Allow null phone
      service_street: address,
      service_requested: finalService || 'Not specified',
      message: messageText,
      file_uploads: fileUpload.uploadedFileUrls.length > 0 ? fileUpload.uploadedFileUrls : null,
      location_name: locationName,
      form_id: formId,
      form_name: formName
    };
    
    // Submit the form
    await formSubmission.submitForm(contactData);
  };

  const retrySubmission = async (formElement: HTMLFormElement, formData: Record<string, any>) => {
    // Ensure formData has the required properties for SubmitFormData
    const submissionData = {
      client_name: formData.name || '',
      client_email: formData.email || '',
      client_phone: formData.phone?.replace(/\D/g, '') || '',
      service_street: formData.address || '',
      service_requested: formData.service || 'Not specified',
      message: formData.message || '',
      file_uploads: formData.file_uploads || null,
      form_id: formId,
      form_name: formName
    };
    
    await formSubmission.submitForm(submissionData);
  };

  const resetForm = () => {
    formSubmission.resetForm();
    // We don't reset the form persistence data as it's meant to be saved
  };

  return {
    // Personal info fields
    ...formPersistence,
    
    // Service selection
    ...serviceSelection,
    
    // File upload
    ...fileUpload,
    
    // Form submission
    isSubmitting: formSubmission.isSubmitting,
    submissionState: formSubmission.submissionState,
    submissionError: formSubmission.submissionError,
    leadId: formSubmission.leadId,
    clientName: formSubmission.clientName,
    
    // Form actions
    handleSubmit,
    retrySubmission,
    resetForm,
    
    // UI settings
    hideHeader
  };
};
