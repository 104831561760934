/**
 * ROUTES CONFIGURATION
 * 
 * This file serves as the single source of truth for all application routes.
 * All route paths should be defined here and imported where needed to ensure consistency.
 * 
 * IMPORTANT GUIDELINES:
 * 1. Never hardcode route paths in components - always import from this file
 * 2. Keep routes organized by section (public, admin, auth)
 * 3. Include comments for routes with parameters
 * 4. Document any route changes in comments to maintain history
 */

// Public Routes
export const PUBLIC_ROUTES = {
  HOME: '/',
  ABOUT: '/about',
  ABOUT_LEGACY: '/about-us', // redirects to /about
  SERVICES: '/our-services',
  SERVICES_LEGACY: '/services', // redirects to /our-services
  SERVICE_DETAIL: '/services/:slug', // Parameter: slug - service slug
  SERVICE_DETAIL_PATH: (slug: string) => `/services/${slug}`,
  OUR_SERVICE_DETAIL: '/our-services/:slug', // Parameter: slug - service slug
  OUR_SERVICE_DETAIL_PATH: (slug: string) => `/our-services/${slug}`,
  CONTACT: '/contact',
  GALLERY: '/gallery',
  GALLERY_DETAIL: '/gallery/:slug', // Parameter: slug - gallery slug
  GALLERY_DETAIL_PATH: (slug: string) => `/gallery/${slug}`,
  GALLERY_LEGACY: '/galleries', // redirects to /gallery
  BLOG: '/blog',
  BLOG_POST: '/blog/:slug', // Parameter: slug - blog post slug
  BLOG_POST_PATH: (slug: string) => `/blog/${slug}`,
  LOCATIONS: '/locations',
  LOCATION_DETAIL: '/locations/:locationSlug', // Parameter: locationSlug - location slug
  LOCATION_DETAIL_PATH: (locationSlug: string) => `/locations/${locationSlug}`,
  SUBLOCATION_DETAIL: '/locations/:locationSlug/:sublocationSlug', // Parameters: locationSlug, sublocationSlug
  SUBLOCATION_DETAIL_PATH: (locationSlug: string, sublocationSlug: string) => `/locations/${locationSlug}/${sublocationSlug}`,
  LOCATION_SERVICE_DETAIL: '/locations/:locationSlug/services/:serviceSlug', // Parameters: locationSlug, serviceSlug
  LOCATION_SERVICE_DETAIL_PATH: (locationSlug: string, serviceSlug: string) => `/locations/${locationSlug}/services/${serviceSlug}`,
  REVIEWS: '/reviews',
  WSIB: '/wsib',
  PROJECTS: '/projects',
  PROJECT_DETAIL: '/projects/:slug', // Parameter: slug - project slug
  PROJECT_DETAIL_PATH: (slug: string) => `/projects/${slug}`,
  SITEMAP: '/sitemap',
  SITEMAP_UPPERCASE: '/SiteMap', // support case variations
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_OF_SERVICE: '/terms-of-service',
  SERVICES_BY_LOCATION: '/services-by-location'
};

// Auth Routes
export const AUTH_ROUTES = {
  LOGIN: '/admin/login',
  RESET_PASSWORD: '/admin/reset-password'
};

// Admin Routes
export const ADMIN_ROUTES = {
  DASHBOARD: '/admin',
  DASHBOARD_LEGACY: '/admin/dashboard', // redirects to /admin
  INVITE: '/admin/invite',
  
  // Services
  SERVICES: '/admin/services',
  EDIT_SERVICE: '/admin/edit-service/:id', // Parameter: id - service ID or 'new'
  EDIT_SERVICE_PATH: (id: string) => `/admin/edit-service/${id}`,
  
  // Locations
  LOCATIONS: '/admin/locations',
  EDIT_LOCATION: '/admin/edit-location/:id', // Parameter: id - location ID or 'new'
  EDIT_LOCATION_PATH: (id: string) => `/admin/edit-location/${id}`,
  
  // Location Services
  LOCATION_SERVICES: '/admin/location-services',
  EDIT_LOCATION_SERVICE: '/admin/location-services/edit/:id', // Parameter: id - location service ID or 'new'
  EDIT_LOCATION_SERVICE_PATH: (id: string) => `/admin/location-services/edit/${id}`,
  
  // Projects
  PROJECTS: '/admin/projects',
  EDIT_PROJECT: '/admin/edit-project/:id', // Parameter: id - project ID or 'new'
  EDIT_PROJECT_PATH: (id: string) => `/admin/edit-project/${id}`,
  
  // Content
  REVIEWS: '/admin/reviews',
  LEADS: '/admin/leads',
  STATIC_PAGES: '/admin/static-pages',
  EDIT_PAGE: '/admin/edit-page/:id', // Parameter: id - page ID or 'new'
  EDIT_PAGE_PATH: (id: string) => `/admin/edit-page/${id}`,
  
  // Blog
  BLOG: '/admin/blog',
  BLOG_POSTS: '/admin/blog/posts',
  BLOG_POST: '/admin/blog/post/:id', // Parameter: id - blog post ID or 'new'
  BLOG_POST_PATH: (id: string) => `/admin/blog/post/${id}`,
  BLOG_CATEGORIES: '/admin/blog/categories',
  BLOG_TAGS: '/admin/blog/tags',
  
  // Galleries
  GALLERIES: '/admin/galleries',
  EDIT_GALLERY: '/admin/gallery/edit/:id', // Parameter: id - gallery ID or 'new'
  EDIT_GALLERY_PATH: (id: string) => `/admin/gallery/edit/${id}`,
  GALLERY_PHOTOS: '/admin/gallery/photos/:id', // Parameter: id - gallery ID
  GALLERY_PHOTOS_PATH: (id: string) => `/admin/gallery/photos/${id}`,
  
  // Settings & Miscellaneous 
  SETTINGS: '/admin/settings',
  WSIB: '/admin/wsib',
  GMB_LOCATIONS: '/admin/gmb-locations',
  FRANCHISE_IMAGES: '/admin/franchise-images',
  ASSETS: '/admin/assets',
  MIGRATION: '/admin/migration',
  TABLE_STANDARDS: '/admin/table-standards'
};

// Special Routes
export const SPECIAL_ROUTES = {
  NOT_FOUND: '*'
};