
import React from 'react';
import {
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import FileUploadArea from './FileUploadArea';

interface UploadDialogProps {
  onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  uploading: boolean;
  onClose?: () => void;
}

const UploadDialog = ({ onFileChange, uploading, onClose }: UploadDialogProps) => {
  return (
    <DialogContent className='sm:max-w-md'>
      <DialogHeader className='space-y-1'>
        <DialogTitle>Upload Image</DialogTitle>
        <DialogDescription>
          Choose an image to upload for your gallery cover
        </DialogDescription>
      </DialogHeader>
      <div className='space-y-4 py-4'>
        <FileUploadArea onFileChange={onFileChange} uploading={uploading} />
      </div>
      <DialogFooter>
        <Button
          variant="outline"
          onClick={onClose}
          disabled={uploading}
        >
          Cancel
        </Button>
      </DialogFooter>
    </DialogContent>
  );
};

export default UploadDialog;
