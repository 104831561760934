import React, { useEffect } from 'react';
import { Button } from '@/components/ui/button';
import ServiceBackground from '@/components/service/ServiceBackground';
import { ServiceDetail } from '@/types/service';
import ContactRequestForm from '@/components/shared/ContactRequestForm';
import { PhoneButton } from '@/components/ui/phone-button';
import { useModal } from '@/components/shared/modal/ModalContext';
import MobileFloatingBar from '@/components/shared/MobileFloatingBar';
import ReviewCarousel from '@/components/shared/ReviewCarousel';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/lib/supabase';
import { Review } from '@/lib/supabase/reviews';
import { useServiceTracking } from '@/lib/tracking/hooks/useServiceTracking';
import ReviewCard from '@/components/ui/review-card';
import { useSettings } from '@/hooks/useSettings';

interface ServiceHeroProps {
  service: {
    id: string; // Updated to match the ServiceDetail interface
    title: string;
    slug: string;
    description: string;
    hero_image?: string;
    image_url?: string;
    hero_headline?: string;
    review_ids?: string[];
  };
}

const ServiceHero: React.FC<ServiceHeroProps> = ({ service }) => {
  const { openContactModal } = useModal();
  const { trackServiceView, trackServiceInteraction } = useServiceTracking({
    serviceId: service.id,
    serviceName: service.title,
    serviceSlug: service.slug,
  });
  const { settings } = useSettings();

  // Track service view on mount
  useEffect(() => {
    trackServiceView({
      view_type: 'hero',
      has_hero_image: !!service.hero_image,
    });
  }, [service.id, trackServiceView, service.hero_image]);

  // Track scroll depth
  useEffect(() => {
    let scrollTimeout: NodeJS.Timeout;
    const handleScroll = () => {
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        const scrollPercent = Math.round(
          (window.scrollY /
            (document.documentElement.scrollHeight - window.innerHeight)) *
            100
        );
        if (scrollPercent > 25) {
          trackServiceInteraction('scroll', 'page', {
            scroll_depth: scrollPercent,
          });
        }
      }, 500);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(scrollTimeout);
    };
  }, [trackServiceInteraction]);

  // We don't need this anymore since ServiceBackground component handles it
  // const backgroundImageUrl = service.hero_image || service.image_url || '';

  // Enhanced debugging - log all service data
  useEffect(() => {
    if (service) {
      console.log('Service data for debugging:', {
        service_id: service.id,
        service_title: service.title,
        hero_image: service.hero_image,
        image_url: service.image_url,
        service_object: service,
      });
    }
  }, [service]);

  const handleQuoteButtonClick = () => {
    openContactModal(service.slug);
  };
  const reviewCount = service.review_ids?.length || 0;

  // Fetch the reviews for this service - increased limit since this is now the only reviews section
  const { data: reviews } = useQuery({
    queryKey: ['service-reviews-preview', service.id, service.review_ids],
    queryFn: async () => {
      if (!service.review_ids || service.review_ids.length === 0) {
        return [];
      }
      const { data, error } = await supabase
        .from('reviews')
        .select('*')
        .in('id', service.review_ids)
        .order('create_time', {
          ascending: false,
        })
        .limit(6); // Increased from 5 to 6 to show more reviews
      if (error) throw error;
      return data as Review[];
    },
    enabled: Array.isArray(service.review_ids) && service.review_ids.length > 0,
  });

  // Create custom text color class using the primary color from settings
  const textColorClass = 'text-[#D00B0E]';

  return (
    <>
      <section className='relative min-h-[50vh] md:min-h-[60vh] flex items-center text-white py-12 md:py-20'>
        {/* Use the new ServiceBackground component for consistent rendering */}
        <ServiceBackground service={service} />

        <div className='container mx-auto px-4 relative z-[2]'>
          <div className='grid grid-cols-1 lg:grid-cols-2 gap-12 items-center'>
            {/* Left column: Content */}
            <div>
              <h1 className='text-3xl sm:text-4xl md:text-5xl font-bold mb-4 md:mb-6'>
                {service.hero_headline || service.title}
              </h1>
              <p className='text-base sm:text-lg md:text-xl leading-relaxed text-gray-200 mb-6 md:mb-8'>
                {service.description}
              </p>
            </div>

            {/* Right column: Form (hidden on mobile) */}
            <div className='hidden lg:block'>
              <div className='bg-white rounded-lg shadow-xl overflow-hidden'>
                <ContactRequestForm
                  defaultService={service.title}
                  serviceSlug={service.slug}
                />
              </div>
            </div>
          </div>
        </div>

        <MobileFloatingBar serviceTitle={service.title} />
      </section>
    </>
  );
};

export default ServiceHero;
