/**
 * Helper utilities to work with location types
 */

/**
 * Determine if a location is a primary location (has a physical address)
 * @param location Location object
 * @returns boolean
 */
export const isPrimaryLocation = (location: any): boolean => {
  // A location is primary if it has an address or coordinates
  return Boolean(
    location?.address ||
      (location?.latitude && location?.longitude) ||
      location?.is_primary_location
  );
};

/**
 * Generate a formatted address string from a location object
 * @param location Location object with address components
 * @returns Formatted address string or empty string
 */
export const formatAddress = (location: any): string => {
  if (!location) return '';

  if (location?.address) {
    return location.address;
  }

  // If no complete address, try to build from components if available
  const components = [];

  if (location?.street_address) components.push(location.street_address);
  if (location?.city) components.push(location.city);
  if (location?.state || location?.province)
    components.push(location.state || location.province);
  if (location?.postal_code || location?.zip_code)
    components.push(location.postal_code || location.zip_code);

  return components.join(', ');
};

/**
 * Get geo coordinates from a location if available
 * @param location Location object with latitude/longitude
 * @returns Geo coordinate object or null
 */
export const getGeoCoordinates = (location: any) => {
  if (!location || !location.latitude || !location.longitude) {
    return null;
  }

  return {
    '@type': 'GeoCoordinates',
    latitude: location.latitude,
    longitude: location.longitude,
  };
};

/**
 * Get area served from a location if available
 * @param location Location object
 * @returns AreaServed object or null
 */
export const getAreaServed = (location: any) => {
  if (!location || !location.name) {
    return null;
  }

  // If location has a defined service area, use that
  if (location.service_area) {
    return {
      '@type': 'GeoCircle',
      geoMidpoint: getGeoCoordinates(location) || undefined,
      geoRadius: location.service_radius
        ? `${location.service_radius}km`
        : undefined,
      description: `${location.name} service area`,
    };
  }

  // Otherwise, just use the location name as the area served
  return location.name;
};
