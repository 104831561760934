
import React from 'react';
import { ContactRequestForm as ContactForm } from './contact-form';
import type { ContactFormProps } from './contact-form';

const ContactRequestForm: React.FC<ContactFormProps> = (props) => {
  return <ContactForm {...props} />;
};

export default ContactRequestForm;
