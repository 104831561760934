
import { useState } from 'react';
import { toast } from 'sonner';

export interface UseFileUploadProps {
  maxFiles?: number;
}

export const useFileUpload = ({ maxFiles = 5 }: UseFileUploadProps = {}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [uploadedFileUrls, setUploadedFileUrls] = useState<string[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  const handleFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);
      
      // Check if adding these files would exceed the limit
      if (files.length + newFiles.length > maxFiles) {
        toast("File limit exceeded", {
          description: `You can upload a maximum of ${maxFiles} files`
        });
        const updatedFiles = [...files, ...newFiles].slice(0, maxFiles);
        setFiles(updatedFiles);
      } else {
        const updatedFiles = [...files, ...newFiles];
        setFiles(updatedFiles);
      }
      
      // Reset uploaded URLs when new files are added
      setUploadedFileUrls([]);
    }
  };
  
  const removeFile = (index: number) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    
    if (uploadedFileUrls[index]) {
      const updatedUrls = [...uploadedFileUrls];
      updatedUrls.splice(index, 1);
      setUploadedFileUrls(updatedUrls);
    }
  };

  const handleFileUploadComplete = (urls: string[]) => {
    setUploadedFileUrls(urls);
  };

  return {
    files,
    uploadedFileUrls,
    isUploading,
    setIsUploading,
    handleFilesChange,
    removeFile,
    handleFileUploadComplete,
    isUploadComplete: files.length === 0 || uploadedFileUrls.length === files.length,
  };
};
