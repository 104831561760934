
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { X, FileText, MapPin, Info, BookOpen, Star, Phone, Image, FileCheck, Home, ChevronRight, ChevronDown } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { LocationLinks, serviceLinks } from './navigationData';

// Add CSS animation for the dropdown menus
const dropdownStyle = `
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes slideIn {
  from { transform: translateX(-10px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}
`;

interface MobileNavigationProps {
  closeMenu: () => void;
  phoneNumber?: string;
}

const MobileNavigation = ({ closeMenu, phoneNumber }: MobileNavigationProps) => {
  const [servicesExpanded, setServicesExpanded] = useState(false);
  const [locationsExpanded, setLocationsExpanded] = useState(false);
  
  const toggleServices = (e: React.MouseEvent) => {
    e.preventDefault();
    setServicesExpanded(!servicesExpanded);
  };
  
  const toggleLocations = (e: React.MouseEvent) => {
    e.preventDefault();
    setLocationsExpanded(!locationsExpanded);
  };
  
  return (
    <div className="md:hidden py-4 border-t border-gray-100">
      <style dangerouslySetInnerHTML={{ __html: dropdownStyle }} />
      <div className="flex flex-col space-y-4">
        <div className="px-5 pb-4 border-b border-gray-100">
          <Link
            to="/contact"
            className="block w-full"
            onClick={closeMenu}
          >
            <Button className="w-full bg-brick hover:bg-brick-dark text-white font-medium shadow-md py-2.5 rounded-md transition-all duration-200 hover:scale-[1.02]">
              Get a Quote
            </Button>
          </Link>
        </div>
        
        <div className="px-5 pb-3">
          <div className="text-sm uppercase tracking-wide text-gray-500 font-semibold mb-3">Menu</div>
          <div className="flex flex-col space-y-4">
            <Link
              to="/"
              className="flex items-center text-gray-700 hover:text-brick transition-colors group"
              onClick={closeMenu}
            >
              <div className="bg-gray-100 rounded-full p-1.5 mr-3 group-hover:bg-brick/10 transition-colors">
                <Home className="h-4 w-4 group-hover:text-brick transition-colors" />
              </div>
              <span className="font-medium">Home</span>
            </Link>
            
            <div className="flex flex-col space-y-2">
              <button 
                onClick={toggleServices}
                className="flex items-center justify-between text-gray-700 hover:text-brick font-medium w-full text-left transition-colors group"
              >
                <span className="flex items-center">
                  <div className="bg-gray-100 rounded-full p-1.5 mr-3 group-hover:bg-brick/10 transition-colors">
                    <FileText className="h-4 w-4 group-hover:text-brick transition-colors" />
                  </div>
                  Services
                </span>
                {servicesExpanded ? 
                  <ChevronDown className="h-4 w-4 text-brick transition-colors" /> : 
                  <ChevronRight className="h-4 w-4 transition-colors" />
                }
              </button>
              
              {servicesExpanded && (
                <div className="pl-10 mt-1 flex flex-col space-y-3 transition-all duration-300 origin-top" 
                     style={{ animation: 'fadeIn 0.3s ease-out' }}>
                  {serviceLinks.map((service, index) => (
                    <Link
                      key={service.slug}
                      to={`/our-services/${service.slug}`}
                      className="text-sm text-gray-600 hover:text-brick transition-colors"
                      onClick={closeMenu}
                      style={{ animation: `slideIn 0.2s ease-out forwards ${index * 0.03}s` }}
                    >
                      {service.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>
            
            <div className="flex flex-col space-y-2">
              <button 
                onClick={toggleLocations}
                className="flex items-center justify-between text-gray-700 hover:text-brick font-medium w-full text-left transition-colors group"
              >
                <span className="flex items-center">
                  <div className="bg-gray-100 rounded-full p-1.5 mr-3 group-hover:bg-brick/10 transition-colors">
                    <MapPin className="h-4 w-4 group-hover:text-brick transition-colors" />
                  </div>
                  Locations
                </span>
                {locationsExpanded ? 
                  <ChevronDown className="h-4 w-4 text-brick transition-colors" /> : 
                  <ChevronRight className="h-4 w-4 transition-colors" />
                }
              </button>
              
              {locationsExpanded && (
                <div className="pl-10 mt-1 flex flex-col space-y-3 transition-all duration-300 origin-top" 
                     style={{ animation: 'fadeIn 0.3s ease-out' }}>
                  {LocationLinks.map((location, index) => (
                    <Link
                      key={location.slug}
                      to={`/locations/${location.slug}`}
                      className="text-sm text-gray-600 hover:text-brick transition-colors"
                      onClick={closeMenu}
                      style={{ animation: `slideIn 0.2s ease-out forwards ${index * 0.03}s` }}
                    >
                      {location.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>
            
            {/* Blog link removed */}
            
            <Link
              to="/reviews"
              className="flex items-center text-gray-700 hover:text-brick transition-colors group"
              onClick={closeMenu}
            >
              <div className="bg-gray-100 rounded-full p-1.5 mr-3 group-hover:bg-brick/10 transition-colors">
                <Star className="h-4 w-4 group-hover:text-brick transition-colors" />
              </div>
              <span className="font-medium">Reviews</span>
            </Link>
            
            <Link
              to="/wsib"
              className="flex items-center text-gray-700 hover:text-brick transition-colors group"
              onClick={closeMenu}
            >
              <div className="bg-gray-100 rounded-full p-1.5 mr-3 group-hover:bg-brick/10 transition-colors">
                <FileCheck className="h-4 w-4 group-hover:text-brick transition-colors" />
              </div>
              <span className="font-medium">WSIB</span>
            </Link>
          </div>
        </div>
        
        {phoneNumber && (
          <div className="px-5 pt-3 border-t border-gray-100">
            <a 
              href={`tel:${phoneNumber.replace(/\D/g, '')}`}
              className="flex items-center text-gray-700 hover:text-brick transition-colors group"
            >
              <div className="bg-brick/10 rounded-full p-1.5 mr-3 group-hover:bg-brick/20 transition-colors">
                <Phone className="h-4 w-4 text-brick" />
              </div>
              <span className="font-medium">{phoneNumber}</span>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileNavigation;
