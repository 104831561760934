import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import { Location } from '@/types/location';
import { ServiceDetail } from '@/types/service';
import { useServicesQuery } from '@/hooks/useServiceQuery';
import { Card, CardContent } from '@/components/ui/card';
import { useLocationServicesByLocationQuery } from '@/hooks/useLocationServiceQuery';
import { LocationService } from '@/types/location-service';

interface LocationServicesProps {
  location: Location;
}

const LocationServices: React.FC<LocationServicesProps> = ({ location }) => {
  const { data: services, isLoading: servicesLoading } = useServicesQuery();
  const { data: locationServices, isLoading: locationServicesLoading } = 
    useLocationServicesByLocationQuery(location.id ? String(location.id) : '');
  
  const [featuredServices, setFeaturedServices] = useState<ServiceDetail[]>([]);
  const isLoading = servicesLoading || locationServicesLoading;
  
  useEffect(() => {
    // Filter for services when data is available
    if (services && services.length > 0 && locationServices) {
      // First prioritize services that have location-specific versions
      const locationServiceSlugs = locationServices.map(ls => ls.services?.slug);
      const servicesWithLocationVersion = services.filter(service => 
        locationServiceSlugs.includes(service.slug)
      );
      
      // If we have enough location-specific services, show those
      if (servicesWithLocationVersion.length >= 4) {
        setFeaturedServices(servicesWithLocationVersion.slice(0, 4));
      } else {
        // Otherwise, fill remaining slots with featured services
        const remainingCount = 4 - servicesWithLocationVersion.length;
        const otherFeaturedServices = services
          .filter(s => s.is_featured && !locationServiceSlugs.includes(s.slug))
          .slice(0, remainingCount);
        
        setFeaturedServices([...servicesWithLocationVersion, ...otherFeaturedServices]);
      }
    } else if (services && services.length > 0) {
      // Fallback to featured services if no location services
      const featured = services.filter(service => service.is_featured);
      setFeaturedServices(featured.length > 0 ? featured : services.slice(0, 4));
    }
  }, [services, locationServices]);

  // Helper function to check if a location-specific service exists
  const getLocationService = (serviceSlug: string): LocationService | undefined => {
    if (!locationServices) return undefined;
    return locationServices.find(
      ls => ls.services?.slug === serviceSlug
    );
  };

  return (
    <section className="bg-gray-50 py-16" id="services">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Our Services in {location.name}</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            We provide a wide range of masonry services throughout {location.name}. Our team of experts is ready to help with your next project.
          </p>
        </div>
        
        {isLoading ? (
          <div className="flex justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#D00B0E]"></div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {featuredServices.map((service) => {
              // Check if we have a location-specific service
              const locationService = getLocationService(service.slug);
              
              // Determine the link URL based on whether we have a location-specific service
              const serviceUrl = locationService 
                ? `/locations/${location.slug}/services/${service.slug}`
                : `/services/${service.slug}`;
                
              return (
                <Card key={service.slug} className="overflow-hidden flex flex-col h-full border border-gray-100 shadow-sm relative">
                  {/* Location-specific badge */}
                  {locationService && (
                    <div className="absolute top-2 right-2 z-10">
                      <span className="bg-[#D00B0E]/10 text-[#D00B0E] text-xs font-medium px-2 py-1 rounded-full">
                        {location.name} Specific
                      </span>
                    </div>
                  )}
                  
                  {service.image_url && (
                    <div className="h-48 overflow-hidden">
                      <img 
                        src={service.image_url} 
                        alt={service.title} 
                        className="w-full h-full object-cover"
                      />
                    </div>
                  )}
                  <CardContent className="p-6 flex flex-col flex-grow">
                    <h3 className="text-xl font-semibold mb-3">
                      {locationService ? locationService.title : `${service.title} in ${location.name}`}
                    </h3>
                    <p className="text-gray-600 mb-4 flex-grow">
                      {locationService?.description || service.description}
                    </p>
                    <Link 
                      to={serviceUrl}
                      className="text-[#D00B0E] hover:underline flex items-center mt-2"
                    >
                      Learn more <ArrowRight className="h-4 w-4 ml-1" />
                    </Link>
                  </CardContent>
                </Card>
              );
            })}
          </div>
        )}
        
        <div className="text-center mt-12 flex flex-wrap justify-center gap-4">
          <Link 
            to={`/services-by-location?location=${location.slug}`}
            className="bg-[#D00B0E] hover:bg-[#D00B0E]/90 text-white px-6 py-3 rounded-md font-medium transition-colors inline-block"
          >
            View All Services in {location.name}
          </Link>
          
          <Link 
            to="/services-by-location"
            className="border border-[#D00B0E] text-[#D00B0E] hover:bg-[#D00B0E]/5 px-6 py-3 rounded-md font-medium transition-colors inline-block"
          >
            Browse Services by Location
          </Link>
        </div>
      </div>
    </section>
  );
};

export default LocationServices;
