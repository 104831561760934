import {
  SchemaContext,
  getOrganizationSchema,
  mergeSchema,
} from './baseSchema';
import { getAreaServed } from './locationTypeHelper';

/**
 * Generate schema for a service page
 * @param service The service data
 * @param context Schema context with URL and settings
 * @param location Optional location data if service is location-specific
 * @returns Schema.org JSON-LD object
 */
export const getServiceSchema = (
  service: any,
  context: SchemaContext,
  location?: any
) => {
  const { url, origin, settings } = context;

  // Skip if no service data
  if (!service) return null;

  // Get the base organization data as the provider
  const providerSchema = {
    '@type': 'Organization',
    '@id': origin,
    name: settings.business_name || 'Fix My Brick',
  };

  // Determine area served - either from location parameter or general area
  const areaServed = location ? getAreaServed(location) : 'Ontario';

  // Create the service schema
  const serviceSchema = {
    '@context': 'https://schema.org',
    '@type': 'Service',
    '@id': `${origin}/services/${service.slug}`,
    name: service.title,
    description: service.description || service.full_description,
    url: url,
    provider: providerSchema,
    areaServed: areaServed,
    // Add contact information
    telephone: settings.phone_number,
    email: settings.email,
    // Add image if available
    image: service.image_url || service.hero_image || settings.default_og_image,
  };

  // Add offers if there's pricing or CTA
  if (service.price || service.pricing || service.cta_text) {
    serviceSchema['offers'] = {
      '@type': 'Offer',
      // Include price if available
      ...(service.price && { price: service.price, priceCurrency: 'CAD' }),
      // Otherwise just mark it as available
      availability: 'https://schema.org/InStock',
      // Add the CTA text as the offer description
      description: service.cta_text || 'Contact us for service',
    };
  }

  // If there are service steps/process, include them
  if (service.process_steps && service.process_steps.length > 0) {
    serviceSchema['serviceOutput'] = {
      '@type': 'ItemList',
      numberOfItems: service.process_steps.length,
      itemListElement: service.process_steps.map((step, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        name: step.title,
        description: step.description,
      })),
    };
  }

  // If there are benefits, include them as well
  if (service.benefits && service.benefits.length > 0) {
    serviceSchema['serviceOutput'] = {
      ...(serviceSchema['serviceOutput'] || {}),
      additionalProperty: service.benefits.map((benefit) => ({
        '@type': 'PropertyValue',
        name: 'Benefit',
        value: benefit,
      })),
    };
  }

  return serviceSchema;
};
