
import React from 'react';
import { Skeleton } from "@/components/ui/skeleton";
import LazyImage from '@/components/ui/lazy-image';

interface ImagePreviewProps {
  uploading: boolean;
  previewUrl: string | null;
  alt?: string;
}

const ImagePreview = ({ uploading, previewUrl, alt = "Preview image" }: ImagePreviewProps) => {
  if (uploading) {
    return <Skeleton className="h-48 w-full" />;
  }

  if (previewUrl) {
    return (
      <div className="relative w-full">
        <LazyImage
          src={previewUrl}
          alt={alt}
          className="w-full h-48 object-cover"
          fallback={
            <div className="flex items-center justify-center h-48 w-full bg-muted/30">
              <Skeleton className="h-full w-full" />
            </div>
          }
          fallbackSrc="/images/placeholder-image.jpg"
        />
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center h-48 text-muted-foreground bg-muted/10 rounded border border-dashed">
      <p>No image selected</p>
    </div>
  );
};

export default ImagePreview;
