import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSettings } from '@/hooks/useSettings';
// Import new schema utilities
import {
  getSchemaContext,
  getOrganizationSchema,
  mergeSchema,
} from './schema/baseSchema';
import { validateSchema } from './schema/schemaValidation';

interface PageMetaProps {
  title?: string;
  description?: string;
  image?: string;
  canonical?: string;
  type?: 'website' | 'article';
  schema?: Record<string, any>;
}

const PageMeta: React.FC<PageMetaProps> = ({
  title,
  description,
  image,
  canonical,
  type = 'website',
  schema,
}) => {
  const { settings, loading } = useSettings();
  const siteTitle = settings.business_name || 'Fix My Brick';

  // Use provided title or fall back to default meta title from settings
  // Handle case where settings.default_meta_title might not exist yet
  const pageTitle =
    title ||
    (settings.default_meta_title
      ? settings.default_meta_title
      : `${siteTitle} | Professional Masonry Services`);

  // Format title properly, ensuring business name is included
  const fullTitle = title
    ? title.includes(siteTitle)
      ? title
      : `${title} | ${siteTitle}`
    : pageTitle;

  // Use provided description or fall back to default
  // Handle case where settings.default_meta_description might not exist yet
  const metaDescription =
    description ||
    (settings.default_meta_description
      ? settings.default_meta_description
      : 'Fix My Brick provides professional brick and masonry repair services across Ontario.');

  // Use provided image or fall back to default OG image
  // Handle case where settings.default_og_image might not exist yet
  const ogImage =
    image ||
    (settings.default_og_image ? settings.default_og_image : '/og-image.png');

  // Get current URL for og:url if no canonical is provided
  const currentUrl = window.location.href;
  const canonicalUrl = canonical || currentUrl;

  // Generate structured data based on settings and provided schema
  const generateStructuredData = () => {
    // Handle case where settings.enable_structured_data might not exist yet
    // Default to true if undefined
    if (settings.enable_structured_data === false) return null;

    // Get schema context with URL and settings
    const context = getSchemaContext();

    // Get base organization schema
    const baseSchema = getOrganizationSchema(context);

    // If custom schema is provided, merge it with the base schema
    if (schema) {
      const mergedSchema = mergeSchema(baseSchema, schema);

      // In development, validate the schema and log any issues
      if (process.env.NODE_ENV === 'development') {
        const validationResult = validateSchema(mergedSchema);
        if (!validationResult.valid) {
          console.group('Schema Validation Issues:');
          if (validationResult.errors.length > 0) {
            console.error('Schema Errors:', validationResult.errors);
          }
          if (validationResult.warnings.length > 0) {
            console.warn('Schema Warnings:', validationResult.warnings);
          }
          console.groupEnd();
        }
      }

      return mergedSchema;
    }

    // Merge with any additional structured data from settings
    // Handle case where settings.schema_org_data might not exist yet
    return mergeSchema(baseSchema, settings.schema_org_data || {});
  };

  const structuredData = generateStructuredData();

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{fullTitle}</title>
      {metaDescription && <meta name='description' content={metaDescription} />}
      <link rel='canonical' href={canonicalUrl} />

      {/* OpenGraph Meta Tags - Enhanced */}
      <meta property='og:title' content={fullTitle} />
      <meta property='og:type' content={type} />
      <meta property='og:url' content={canonicalUrl} />
      <meta property='og:image' content={ogImage} />
      <meta property='og:image:width' content='1200' />
      <meta property='og:image:height' content='630' />
      <meta property='og:image:alt' content={fullTitle} />
      {metaDescription && (
        <meta property='og:description' content={metaDescription} />
      )}
      <meta property='og:site_name' content={siteTitle} />
      <meta property='og:locale' content='en_CA' />

      {/* Twitter Card Meta Tags - Enhanced */}
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={fullTitle} />
      {metaDescription && (
        <meta name='twitter:description' content={metaDescription} />
      )}
      <meta name='twitter:image' content={ogImage} />
      <meta name='twitter:image:alt' content={fullTitle} />
      <meta name='twitter:site' content='@fixmybrick' />
      <meta name='twitter:creator' content='@fixmybrick' />

      {/* Favicon */}
      {settings.favicon_url ? (
        <link rel='icon' href={settings.favicon_url} />
      ) : (
        <link rel='icon' href='/favicon.ico' type='image/x-icon' />
      )}

      {/* Schema.org JSON-LD */}
      {structuredData && (
        <script type='application/ld+json'>
          {JSON.stringify(structuredData)}
        </script>
      )}
    </Helmet>
  );
};

export default PageMeta;
