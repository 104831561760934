import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Helmet } from 'react-helmet-async';
import { toast } from 'sonner';

import AdminLayout from '@/components/admin/AdminLayout';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Switch } from '@/components/ui/switch';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Separator } from '@/components/ui/separator';

import {
  createLocationService,
  updateLocationService,
} from '@/lib/supabase/location-services';
import { supabase } from '@/lib/supabase/client';
import { fetchLocations } from '@/lib/supabase/locations';
import { fetchServices } from '@/lib/supabase/services';
import { LocationService } from '@/types/location-service';
import { Location } from '@/types/location';
import { ServiceDetail } from '@/types/service';
import { generateSlug } from '@/lib/utils';

interface BenefitItem {
  title: string;
  description: string;
}

interface ProcessStep {
  title: string;
  description: string;
}

interface FaqItem {
  question: string;
  answer: string;
}

const AdminEditLocationService = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isNewRecord = id === 'new';

  const [formData, setFormData] = useState<Partial<LocationService>>({
    title: '',
    slug: '',
    description: '',
    full_description: '',
    meta_title: '',
    meta_description: '',
    canonical_url: '',
    focus_keyword: '',
    hero_image: '',
    gallery_images: [],
    benefits: [],
    process_steps: [],
    faq: [],
    is_featured: false,
    status: 'draft',
  });

  const [benefits, setBenefits] = useState<BenefitItem[]>([
    { title: '', description: '' },
  ]);
  const [processSteps, setProcessSteps] = useState<ProcessStep[]>([
    { title: '', description: '' },
  ]);
  const [faqs, setFaqs] = useState<FaqItem[]>([{ question: '', answer: '' }]);
  const [activeTab, setActiveTab] = useState('general');
  const [selectedLocation, setSelectedLocation] = useState<string>('');
  const [selectedService, setSelectedService] = useState<string>('');

  // Fetch existing location service if editing
  const {
    data: locationServiceData,
    isLoading: isLoadingLocationService,
    error: locationServiceError,
  } = useQuery({
    queryKey: ['locationService', id],
    queryFn: async () => {
      if (!id || isNewRecord) return null;

      // Use the correct function to fetch a single location service by ID
      const { data, error } = await supabase
        .from('location_services')
        .select('*, locations(*), services(*)')
        .eq('id', id)
        .single();

      if (error) {
        console.error(`Error fetching location service with ID ${id}:`, error);
        return null;
      }

      return data;
    },
    enabled: !isNewRecord && !!id,
  });
  const locationService = locationServiceData as
    | LocationService
    | null
    | undefined;

  // Fetch locations for dropdown
  const {
    data: locationsData,
    isLoading: isLoadingLocations,
    error: locationsError,
  } = useQuery({
    queryKey: ['locations'],
    queryFn: async () => {
      return await fetchLocations();
    },
  });
  const locations = locationsData as Location[] | undefined;

  // Fetch services for dropdown
  const {
    data: servicesData,
    isLoading: isLoadingServices,
    error: servicesError,
  } = useQuery({
    queryKey: ['services'],
    queryFn: async () => {
      return await fetchServices();
    },
  });
  const services = servicesData as ServiceDetail[] | undefined;

  // Create mutation
  const createMutation = useMutation({
    mutationFn: async (data: Omit<LocationService, 'id'>) => {
      return await createLocationService(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['locationServices'] });
      toast.success('Location service created successfully');
      navigate('/admin/location-services');
    },
    onError: (error) => {
      console.error('Error creating location service:', error);
      toast.error('Failed to create location service');
    },
  });

  // Update mutation
  const updateMutation = useMutation({
    mutationFn: async ({
      id,
      data,
    }: {
      id: string;
      data: Partial<LocationService>;
    }) => {
      return await updateLocationService(id, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['locationServices'] });
      queryClient.invalidateQueries({ queryKey: ['locationService', id] });
      toast.success('Location service updated successfully');
      navigate('/admin/location-services');
    },
    onError: (error) => {
      console.error('Error updating location service:', error);
      toast.error('Failed to update location service');
    },
  });

  // Check for errors
  const hasError =
    (!isNewRecord && !locationService && !isLoadingLocationService) ||
    locationsError ||
    servicesError;

  if (hasError) {
    return (
      <AdminLayout
        title={
          isNewRecord ? 'Add New Location Service' : 'Edit Location Service'
        }>
        <div className='p-4'>
          <div
            className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative'
            role='alert'>
            <strong className='font-bold'>Error:</strong>
            <span className='block sm:inline'>
              {' '}
              Failed to load data. Please try again later.
            </span>
            <button
              className='mt-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'
              onClick={() => navigate('/admin/location-services')}>
              Return to Location Services
            </button>
          </div>
        </div>
      </AdminLayout>
    );
  }

  const isLoading =
    isLoadingLocationService ||
    isLoadingLocations ||
    isLoadingServices ||
    createMutation.isPending ||
    updateMutation.isPending;

  if (isLoading && !isNewRecord) {
    return (
      <AdminLayout
        title={
          isNewRecord ? 'Add New Location Service' : 'Edit Location Service'
        }>
        <div className='p-4'>Loading...</div>
      </AdminLayout>
    );
  }

  const pageTitle = isNewRecord
    ? 'Add New Location Service'
    : 'Edit Location Service';

  // Populate form data when editing an existing record
  useEffect(() => {
    if (locationService && !isNewRecord) {
      setFormData(locationService);
      setSelectedLocation(String(locationService.location_id || ''));
      setSelectedService(String(locationService.service_id || ''));
      if (locationService.benefits) {
        setBenefits(locationService.benefits as BenefitItem[]);
      }
      if (locationService.process_steps) {
        setProcessSteps(locationService.process_steps as ProcessStep[]);
      }
      if (locationService.faq) {
        setFaqs(locationService.faq as FaqItem[]);
      }
    }
  }, [locationService, isNewRecord]);

  // Handlers for form changes
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Auto-generate slug from title
    if (name === 'title' && (!formData.slug || formData.slug === '')) {
      setFormData((prev) => ({
        ...prev,
        slug: generateSlug(value),
      }));
    }
  };

  const handleSwitchChange = (name: string, checked: boolean) => {
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  const handleSelectChange = (name: string, value: string) => {
    if (name === 'location_id') {
      setSelectedLocation(value);
    } else if (name === 'service_id') {
      setSelectedService(value);
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBenefitChange = (
    index: number,
    field: keyof BenefitItem,
    value: string
  ) => {
    const updatedBenefits = [...benefits];
    updatedBenefits[index] = { ...updatedBenefits[index], [field]: value };
    setBenefits(updatedBenefits);
  };

  const handleAddBenefit = () => {
    setBenefits([...benefits, { title: '', description: '' }]);
  };

  const handleRemoveBenefit = (index: number) => {
    if (benefits.length > 1) {
      const updatedBenefits = benefits.filter((_, i) => i !== index);
      setBenefits(updatedBenefits);
    }
  };

  const handleProcessStepChange = (
    index: number,
    field: keyof ProcessStep,
    value: string
  ) => {
    const updatedSteps = [...processSteps];
    updatedSteps[index] = { ...updatedSteps[index], [field]: value };
    setProcessSteps(updatedSteps);
  };

  const handleAddProcessStep = () => {
    setProcessSteps([...processSteps, { title: '', description: '' }]);
  };

  const handleRemoveProcessStep = (index: number) => {
    if (processSteps.length > 1) {
      const updatedSteps = processSteps.filter((_, i) => i !== index);
      setProcessSteps(updatedSteps);
    }
  };

  const handleFaqChange = (
    index: number,
    field: keyof FaqItem,
    value: string
  ) => {
    const updatedFaqs = [...faqs];
    updatedFaqs[index] = { ...updatedFaqs[index], [field]: value };
    setFaqs(updatedFaqs);
  };

  const handleAddFaq = () => {
    setFaqs([...faqs, { question: '', answer: '' }]);
  };

  const handleRemoveFaq = (index: number) => {
    if (faqs.length > 1) {
      const updatedFaqs = faqs.filter((_, i) => i !== index);
      setFaqs(updatedFaqs);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Basic validation
    if (!formData.title) {
      toast.error('Title is required');
      return;
    }
    if (!selectedLocation) {
      toast.error('Location is required');
      return;
    }
    if (!selectedService) {
      toast.error('Service is required');
      return;
    }

    const finalData: Partial<LocationService> = {
      ...formData,
      location_id: selectedLocation,
      service_id: selectedService,
      slug: formData.slug || generateSlug(formData.title),
      benefits: benefits.filter((b) => b.title.trim() || b.description.trim()),
      process_steps: processSteps.filter(
        (p) => p.title.trim() || p.description.trim()
      ),
      faq: faqs.filter((f) => f.question.trim() || f.answer.trim()),
    };

    if (isNewRecord) {
      // Ensure required fields for creation are present
      const createData: Omit<LocationService, 'id'> = {
        location_id: selectedLocation,
        service_id: selectedService,
        title: finalData.title || '',
        slug: finalData.slug || '',
        description: finalData.description || '',
        full_description: finalData.full_description || '',
        meta_title: finalData.meta_title || '',
        meta_description: finalData.meta_description || '',
        canonical_url: finalData.canonical_url || '',
        focus_keyword: finalData.focus_keyword || '',
        hero_image: finalData.hero_image || '',
        gallery_images: finalData.gallery_images || [],
        benefits: finalData.benefits || [],
        process_steps: finalData.process_steps || [],
        faq: finalData.faq || [],
        is_featured: finalData.is_featured || false,
        status: finalData.status || 'draft',
      };
      createMutation.mutate(createData);
    } else if (id) {
      updateMutation.mutate({ id, data: finalData });
    }
  };

  return (
    <AdminLayout title={pageTitle}>
      <Helmet>
        <title>{pageTitle} | Admin</title>
      </Helmet>
      <div className='p-4 md:p-6'>
        <form onSubmit={handleSubmit}>
          <div className='flex justify-between items-center mb-6'>
            <h1 className='text-3xl font-bold'>
              {isNewRecord ? 'Create New' : 'Edit'} Location Service
            </h1>
            <div className='flex gap-2'>
              <Button
                variant='outline'
                onClick={() => navigate('/admin/location-services')}>
                Cancel
              </Button>
              <Button onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? 'Saving...' : 'Save'}
              </Button>
            </div>
          </div>

          <Tabs value={activeTab} onValueChange={setActiveTab}>
            <TabsList className='grid w-full grid-cols-5'>
              <TabsTrigger value='general'>General</TabsTrigger>
              <TabsTrigger value='content'>Content</TabsTrigger>
              <TabsTrigger value='benefits'>Benefits</TabsTrigger>
              <TabsTrigger value='process'>Process</TabsTrigger>
              <TabsTrigger value='faq'>FAQ</TabsTrigger>
            </TabsList>

            {/* General Tab */}
            <TabsContent value='general'>
              <Card>
                <CardHeader>
                  <CardTitle>General Information</CardTitle>
                </CardHeader>
                <CardContent className='space-y-4'>
                  <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                    <div>
                      <Label htmlFor='location_id'>Location</Label>
                      <Select
                        value={selectedLocation}
                        onValueChange={(value) =>
                          handleSelectChange('location_id', value)
                        }>
                        <SelectTrigger id='location_id'>
                          <SelectValue placeholder='Select a location' />
                        </SelectTrigger>
                        <SelectContent>
                          {locations && locations.length > 0
                            ? locations.map((location) => (
                                <SelectItem
                                  key={location.id}
                                  value={String(location.id)}>
                                  {location.name}
                                </SelectItem>
                              ))
                            : null}
                        </SelectContent>
                      </Select>
                    </div>
                    <div>
                      <Label htmlFor='service_id'>Service</Label>
                      <Select
                        value={selectedService}
                        onValueChange={(value) =>
                          handleSelectChange('service_id', value)
                        }>
                        <SelectTrigger id='service_id'>
                          <SelectValue placeholder='Select a service' />
                        </SelectTrigger>
                        <SelectContent>
                          {services && services.length > 0
                            ? services.map((service) => (
                                <SelectItem
                                  key={service.id}
                                  value={String(service.id)}>
                                  {service.title}
                                </SelectItem>
                              ))
                            : null}
                        </SelectContent>
                      </Select>
                    </div>
                  </div>

                  <div>
                    <Label htmlFor='title'>Title</Label>
                    <Input
                      id='title'
                      name='title'
                      value={formData.title || ''}
                      onChange={handleInputChange}
                      placeholder='e.g., Brick Pointing in London'
                    />
                  </div>

                  <div>
                    <Label htmlFor='slug'>Slug</Label>
                    <Input
                      id='slug'
                      name='slug'
                      value={formData.slug || ''}
                      onChange={handleInputChange}
                      placeholder='e.g., brick-pointing'
                    />
                  </div>

                  <div>
                    <Label htmlFor='description'>Short Description</Label>
                    <Textarea
                      id='description'
                      name='description'
                      value={formData.description || ''}
                      onChange={handleInputChange}
                      placeholder='Brief description of the service in this location'
                      rows={3}
                    />
                  </div>

                  <div>
                    <Label htmlFor='status'>Status</Label>
                    <Select
                      value={formData.status || 'draft'}
                      onValueChange={(value) =>
                        handleSelectChange('status', value)
                      }>
                      <SelectTrigger id='status'>
                        <SelectValue placeholder='Select status' />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value='draft'>Draft</SelectItem>
                        <SelectItem value='published'>Published</SelectItem>
                        <SelectItem value='archived'>Archived</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>

                  <div className='flex items-center space-x-2'>
                    <Switch
                      id='is_featured'
                      checked={formData.is_featured || false}
                      onCheckedChange={(checked) =>
                        handleSwitchChange('is_featured', checked)
                      }
                    />
                    <Label htmlFor='is_featured'>Featured</Label>
                  </div>
                </CardContent>
              </Card>
            </TabsContent>

            {/* Content Tab */}
            <TabsContent value='content'>
              <Card>
                <CardHeader>
                  <CardTitle>Content</CardTitle>
                </CardHeader>
                <CardContent className='space-y-4'>
                  <div>
                    <Label htmlFor='full_description'>Full Description</Label>
                    <Textarea
                      id='full_description'
                      name='full_description'
                      value={formData.full_description || ''}
                      onChange={handleInputChange}
                      placeholder='Detailed description of the service in this location'
                      rows={8}
                    />
                  </div>

                  <div>
                    <Label htmlFor='hero_image'>Hero Image URL</Label>
                    <Input
                      id='hero_image'
                      name='hero_image'
                      value={formData.hero_image || ''}
                      onChange={handleInputChange}
                      placeholder='URL to the hero image'
                    />
                  </div>

                  <Separator className='my-4' />

                  <div>
                    <Label htmlFor='meta_title'>Meta Title</Label>
                    <Input
                      id='meta_title'
                      name='meta_title'
                      value={formData.meta_title || ''}
                      onChange={handleInputChange}
                      placeholder='SEO meta title'
                    />
                  </div>

                  <div>
                    <Label htmlFor='meta_description'>Meta Description</Label>
                    <Textarea
                      id='meta_description'
                      name='meta_description'
                      value={formData.meta_description || ''}
                      onChange={handleInputChange}
                      placeholder='SEO meta description'
                      rows={3}
                    />
                  </div>

                  <div>
                    <Label htmlFor='focus_keyword'>Focus Keyword</Label>
                    <Input
                      id='focus_keyword'
                      name='focus_keyword'
                      value={formData.focus_keyword || ''}
                      onChange={handleInputChange}
                      placeholder='Main keyword for SEO'
                    />
                  </div>

                  <div>
                    <Label htmlFor='canonical_url'>Canonical URL</Label>
                    <Input
                      id='canonical_url'
                      name='canonical_url'
                      value={formData.canonical_url || ''}
                      onChange={handleInputChange}
                      placeholder='Canonical URL if needed'
                    />
                  </div>
                </CardContent>
              </Card>
            </TabsContent>

            {/* Benefits Tab */}
            <TabsContent value='benefits'>
              <Card>
                <CardHeader>
                  <CardTitle>Benefits</CardTitle>
                </CardHeader>
                <CardContent>
                  {benefits.map((benefit, index) => (
                    <div key={index} className='mb-6 p-4 border rounded-md'>
                      <div className='flex justify-between items-center mb-2'>
                        <h3 className='text-lg font-medium'>
                          Benefit {index + 1}
                        </h3>
                        <Button
                          type='button'
                          variant='outline'
                          size='sm'
                          onClick={() => handleRemoveBenefit(index)}
                          disabled={benefits.length <= 1}>
                          Remove
                        </Button>
                      </div>
                      <div className='space-y-4'>
                        <div>
                          <Label htmlFor={`benefit-title-${index}`}>
                            Title
                          </Label>
                          <Input
                            id={`benefit-title-${index}`}
                            value={benefit.title}
                            onChange={(e) =>
                              handleBenefitChange(
                                index,
                                'title',
                                e.target.value
                              )
                            }
                            placeholder='Benefit title'
                          />
                        </div>
                        <div>
                          <Label htmlFor={`benefit-description-${index}`}>
                            Description
                          </Label>
                          <Textarea
                            id={`benefit-description-${index}`}
                            value={benefit.description}
                            onChange={(e) =>
                              handleBenefitChange(
                                index,
                                'description',
                                e.target.value
                              )
                            }
                            placeholder='Benefit description'
                            rows={3}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <Button
                    type='button'
                    variant='outline'
                    onClick={handleAddBenefit}
                    className='w-full mt-2'>
                    Add Benefit
                  </Button>
                </CardContent>
              </Card>
            </TabsContent>

            {/* Process Tab */}
            <TabsContent value='process'>
              <Card>
                <CardHeader>
                  <CardTitle>Process Steps</CardTitle>
                </CardHeader>
                <CardContent>
                  {processSteps.map((step, index) => (
                    <div key={index} className='mb-6 p-4 border rounded-md'>
                      <div className='flex justify-between items-center mb-2'>
                        <h3 className='text-lg font-medium'>
                          Step {index + 1}
                        </h3>
                        <Button
                          type='button'
                          variant='outline'
                          size='sm'
                          onClick={() => handleRemoveProcessStep(index)}
                          disabled={processSteps.length <= 1}>
                          Remove
                        </Button>
                      </div>
                      <div className='space-y-4'>
                        <div>
                          <Label htmlFor={`step-title-${index}`}>Title</Label>
                          <Input
                            id={`step-title-${index}`}
                            value={step.title}
                            onChange={(e) =>
                              handleProcessStepChange(
                                index,
                                'title',
                                e.target.value
                              )
                            }
                            placeholder='Step title'
                          />
                        </div>
                        <div>
                          <Label htmlFor={`step-description-${index}`}>
                            Description
                          </Label>
                          <Textarea
                            id={`step-description-${index}`}
                            value={step.description}
                            onChange={(e) =>
                              handleProcessStepChange(
                                index,
                                'description',
                                e.target.value
                              )
                            }
                            placeholder='Step description'
                            rows={3}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <Button
                    type='button'
                    variant='outline'
                    onClick={handleAddProcessStep}
                    className='w-full mt-2'>
                    Add Process Step
                  </Button>
                </CardContent>
              </Card>
            </TabsContent>

            {/* FAQ Tab */}
            <TabsContent value='faq'>
              <Card>
                <CardHeader>
                  <CardTitle>Frequently Asked Questions</CardTitle>
                </CardHeader>
                <CardContent>
                  {faqs.map((faq, index) => (
                    <div key={index} className='mb-6 p-4 border rounded-md'>
                      <div className='flex justify-between items-center mb-2'>
                        <h3 className='text-lg font-medium'>FAQ {index + 1}</h3>
                        <Button
                          type='button'
                          variant='outline'
                          size='sm'
                          onClick={() => handleRemoveFaq(index)}
                          disabled={faqs.length <= 1}>
                          Remove
                        </Button>
                      </div>
                      <div className='space-y-4'>
                        <div>
                          <Label htmlFor={`faq-question-${index}`}>
                            Question
                          </Label>
                          <Input
                            id={`faq-question-${index}`}
                            value={faq.question}
                            onChange={(e) =>
                              handleFaqChange(index, 'question', e.target.value)
                            }
                            placeholder='FAQ question'
                          />
                        </div>
                        <div>
                          <Label htmlFor={`faq-answer-${index}`}>Answer</Label>
                          <Textarea
                            id={`faq-answer-${index}`}
                            value={faq.answer}
                            onChange={(e) =>
                              handleFaqChange(index, 'answer', e.target.value)
                            }
                            placeholder='FAQ answer'
                            rows={4}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <Button
                    type='button'
                    variant='outline'
                    onClick={handleAddFaq}
                    className='w-full mt-2'>
                    Add FAQ
                  </Button>
                </CardContent>
              </Card>
            </TabsContent>
          </Tabs>
        </form>
      </div>
    </AdminLayout>
  );
};

export default AdminEditLocationService;
