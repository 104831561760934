import { supabase } from './client';
import { LocationService } from '@/types/location-service';

// Function to fetch all location services
export async function fetchLocationServices(signal?: AbortSignal) {
  try {
    const { data, error } = await supabase
      .from('location_services')
      .select('*, locations(*), services(*)')
      .abortSignal(signal);

    if (error) {
      console.error('Error fetching location services:', error);
      return [];
    }

    return data || [];
  } catch (error) {
    console.error('Error in fetchLocationServices:', error);
    return [];
  }
}

// Function to fetch location services by location ID
export async function fetchLocationServicesByLocation(
  locationId: string,
  signal?: AbortSignal
) {
  try {
    const { data, error } = await supabase
      .from('location_services')
      .select('*, services(*)')
      .eq('location_id', locationId)
      .abortSignal(signal);

    if (error) {
      console.error(`Error fetching services for location ${locationId}:`, error);
      return [];
    }

    return data || [];
  } catch (error) {
    console.error(`Error in fetchLocationServicesByLocation for ${locationId}:`, error);
    return [];
  }
}

// Function to fetch location services by service ID
export async function fetchLocationServicesByService(
  serviceId: string,
  signal?: AbortSignal
) {
  try {
    const { data, error } = await supabase
      .from('location_services')
      .select('*, locations(*)')
      .eq('service_id', serviceId)
      .abortSignal(signal);

    if (error) {
      console.error(`Error fetching locations for service ${serviceId}:`, error);
      return [];
    }

    return data || [];
  } catch (error) {
    console.error(`Error in fetchLocationServicesByService for ${serviceId}:`, error);
    return [];
  }
}

// Function to fetch a specific location service by location and service IDs
export async function fetchLocationService(
  locationId: string,
  serviceId: string,
  signal?: AbortSignal
) {
  try {
    const { data, error } = await supabase
      .from('location_services')
      .select('*, locations(*), services(*)')
      .eq('location_id', locationId)
      .eq('service_id', serviceId)
      .abortSignal(signal)
      .maybeSingle();

    if (error) {
      console.error(`Error fetching location service:`, error);
      return null;
    }

    return data;
  } catch (error) {
    console.error(`Error in fetchLocationService:`, error);
    return null;
  }
}

// Function to fetch a specific location service by slug
export async function fetchLocationServiceBySlug(
  locationSlug: string,
  serviceSlug: string,
  signal?: AbortSignal
) {
  try {
    // First get the location ID
    const { data: location, error: locationError } = await supabase
      .from('locations')
      .select('id')
      .eq('slug', locationSlug)
      .abortSignal(signal)
      .maybeSingle();

    if (locationError || !location) {
      console.error(`Error fetching location with slug ${locationSlug}:`, locationError);
      return null;
    }

    // Then get the service ID
    const { data: service, error: serviceError } = await supabase
      .from('services')
      .select('id')
      .eq('slug', serviceSlug)
      .abortSignal(signal)
      .maybeSingle();

    if (serviceError || !service) {
      console.error(`Error fetching service with slug ${serviceSlug}:`, serviceError);
      return null;
    }

    // Now get the location service
    const { data, error } = await supabase
      .from('location_services')
      .select('*, locations(*), services(*)')
      .eq('location_id', location.id)
      .eq('service_id', service.id)
      .abortSignal(signal)
      .maybeSingle();

    if (error) {
      console.error(`Error fetching location service:`, error);
      return null;
    }

    return data;
  } catch (error) {
    console.error(`Error in fetchLocationServiceBySlug:`, error);
    return null;
  }
}

// Function to create a new location service
export async function createLocationService(locationService: Omit<LocationService, 'id'>) {
  try {
    const { data, error } = await supabase
      .from('location_services')
      .insert([locationService])
      .select()
      .maybeSingle();

    if (error) {
      console.error('Error creating location service:', error);
      return null;
    }

    return data;
  } catch (error) {
    console.error('Error in createLocationService:', error);
    return null;
  }
}

// Function to update a location service
export async function updateLocationService(id: string, updates: Partial<LocationService>) {
  try {
    const { data, error } = await supabase
      .from('location_services')
      .update({
        ...updates,
        updated_at: new Date().toISOString(),
      })
      .eq('id', id)
      .select()
      .maybeSingle();

    if (error) {
      console.error(`Error updating location service ${id}:`, error);
      return null;
    }

    return data;
  } catch (error) {
    console.error(`Error in updateLocationService for ${id}:`, error);
    return null;
  }
}

// Function to delete a location service
export async function deleteLocationService(id: string) {
  try {
    const { error } = await supabase
      .from('location_services')
      .delete()
      .eq('id', id);

    if (error) {
      console.error(`Error deleting location service ${id}:`, error);
      return false;
    }

    return true;
  } catch (error) {
    console.error(`Error in deleteLocationService for ${id}:`, error);
    return false;
  }
}
