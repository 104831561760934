import React from 'react';
import Modal from './Modal';
import FlyUpMenu from './FlyUpMenu';
import { useModal } from './ModalContext';
import { ContactRequestForm } from '../contact-form';
import ReviewCarousel from '../ReviewCarousel';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/lib/supabase/client';
import { Review } from '@/lib/supabase/reviews';

const ContactFormModal: React.FC = () => {
  const {
    isContactModalOpen,
    closeContactModal,
    serviceSlug,
    locationName,
    isMobile,
    trackingParams,
  } = useModal();

  // Props to pass to the contact form
  const formProps = {
    serviceSlug,
    locationName,
    trackingParams,
  };

  // Fetch service-specific reviews if we have a service slug
  const { data: serviceData } = useQuery({
    queryKey: ['service-detail', serviceSlug],
    queryFn: async () => {
      if (!serviceSlug) return null;

      const { data, error } = await supabase
        .from('services')
        .select('review_ids')
        .eq('slug', serviceSlug)
        .single();

      if (error) throw error;
      return data;
    },
    enabled: !!serviceSlug,
  });

  // Fetch reviews based on the service's review_ids
  const { data: reviews } = useQuery({
    queryKey: ['service-reviews', serviceData?.review_ids],
    queryFn: async () => {
      if (
        !serviceData?.review_ids ||
        !Array.isArray(serviceData.review_ids) ||
        serviceData.review_ids.length === 0
      ) {
        return [];
      }

      const { data, error } = await supabase
        .from('reviews')
        .select('*')
        .in('id', serviceData.review_ids)
        .order('create_time', { ascending: false })
        .limit(5);

      if (error) throw error;
      return data as Review[];
    },
    enabled: !!serviceData?.review_ids,
  });

  // For mobile, use FlyUpMenu with reviews if available; otherwise use Modal
  if (isMobile) {
    return (
      <FlyUpMenu
        isOpen={isContactModalOpen}
        onClose={closeContactModal}
        title='Get a Free Quote'
        className='pb-6'
        allowNestedDialogs={true}>
        {reviews && reviews.length > 0 ? (
          <div className='px-4 py-2 space-y-4'>
            <div className='bg-gray-50 rounded-lg p-4'>
              <h3 className='text-lg font-medium text-gray-900 mb-3'>
                What Our Customers Say
              </h3>
              <ReviewCarousel reviews={reviews} />
            </div>
            <div className='pb-0'>
              <ContactRequestForm {...formProps} hideHeader={true} />
            </div>
          </div>
        ) : (
          <div className='px-0'>
            <ContactRequestForm {...formProps} hideHeader={true} />
          </div>
        )}
      </FlyUpMenu>
    );
  }

  return (
    <Modal
      isOpen={isContactModalOpen}
      onClose={closeContactModal}
      className='max-w-2xl w-full'>
      {reviews && reviews.length > 0 ? (
        <div className='p-4 space-y-4'>
          <div className='bg-gray-50 rounded-lg p-4'>
            <h3 className='text-lg font-medium text-gray-900 mb-3'>
              What Our Customers Say
            </h3>
            <ReviewCarousel reviews={reviews} />
          </div>
          <ContactRequestForm {...formProps} />
        </div>
      ) : (
        <div className='p-4'>
          <ContactRequestForm {...formProps} />
        </div>
      )}
    </Modal>
  );
};

export default ContactFormModal;
