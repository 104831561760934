import imageCompression from 'browser-image-compression';

interface CompressionOptions {
  maxSizeMB: number;
  maxWidthOrHeight: number;
  useWebWorker: boolean;
}

const defaultOptions: CompressionOptions = {
  maxSizeMB: 15, // Match Supabase bucket limit
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};

export const compressImage = async (
  file: File,
  customOptions?: Partial<CompressionOptions>
): Promise<File> => {
  // Skip compression for non-image files
  if (!file.type.startsWith('image/')) {
    return file;
  }

  const options = { ...defaultOptions, ...customOptions };

  try {
    const compressedFile = await imageCompression(file, options);

    // If compression didn't reduce size significantly, return original
    if (compressedFile.size > file.size * 0.9) {
      return file;
    }

    return compressedFile;
  } catch (error) {
    console.error('Image compression failed:', error);
    return file; // Return original file if compression fails
  }
};

export const compressMultipleImages = async (
  files: File[],
  customOptions?: Partial<CompressionOptions>
): Promise<File[]> => {
  const compressPromises = files.map((file) =>
    compressImage(file, customOptions)
  );
  return Promise.all(compressPromises);
};
