
import React, { useRef } from 'react';
import {
  FormHeader,
  PersonalInfoFields,
  ServiceSelectionFields,
  MessageField,
  SubmitButton,
  SubmissionStatus,
} from './';
import { useFormTracking } from '@/lib/tracking/hooks/useFormTracking';
import { FileUploadWithProgress } from './FileUploadWithProgress';
import { useContactFormRefactored } from './hooks/useContactFormRefactored';
import type { ContactFormProps } from './useContactForm';

const ContactRequestFormRefactored: React.FC<ContactFormProps> = (props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const formDataRef = useRef<Record<string, any>>({});

  // Initialize form tracking
  const formId = props.formId || 'contact-form';
  const formName = props.formName || 'Contact Request Form';
  const { trackFormStart, trackFormSubmit, trackFormError } = useFormTracking({
    formId,
    formName,
    serviceType: props.defaultService,
    locationName: props.locationName,
  });

  const {
    // Form state
    isSubmitting,
    submissionState,
    submissionError,
    leadId,
    clientName,
    
    // File upload
    files,
    isUploading,
    setIsUploading,
    uploadedFileUrls,
    handleFilesChange,
    removeFile,
    handleFileUploadComplete,
    
    // Service selection
    selectedService,
    otherService,
    showOtherServiceInput,
    predefinedServices,
    handleServiceChange,
    handleOtherServiceChange,
    
    // Form actions
    handleSubmit,
    retrySubmission,
    resetForm,
    
    // UI settings
    hideHeader
  } = useContactFormRefactored(props);

  // Track form start when user interacts with the form
  const handleFirstInteraction = () => {
    trackFormStart({
      service: selectedService,
      location: props.locationName || 'unknown',
      page_url: window.location.href,
    });

    // Remove the event listener after first interaction
    if (formRef.current) {
      formRef.current.removeEventListener('input', handleFirstInteraction);
    }
  };

  // Add event listener when the component mounts
  React.useEffect(() => {
    const form = formRef.current;
    if (form) {
      form.addEventListener('input', handleFirstInteraction);
      return () => form.removeEventListener('input', handleFirstInteraction);
    }
  }, []);

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Store form data for retry
    const formElement = e.currentTarget;
    const formData = new FormData(formElement);

    const finalService = selectedService
      ? showOtherServiceInput
        ? otherService
        : selectedService
      : null;

    formDataRef.current = {
      name: formData.get('name')?.toString() || '',
      email: formData.get('email')?.toString() || '',
      phone: formData.get('phone')?.toString() || '',
      address: formData.get('address')?.toString() || '',
      message: formData.get('message')?.toString() || '',
      service: finalService,
      service_slug: props.serviceSlug || null,
      location: props.locationName || null,
      submission_url: window.location.href,
      form_id: formId,
      file_uploads: uploadedFileUrls,
    };

    try {
      // Track form submission
      trackFormSubmit({
        form_name: formName,
        service: finalService,
        location: props.locationName || 'unknown',
        page_url: window.location.href,
        file_count: uploadedFileUrls.length,
      });

      await handleSubmit(e);
    } catch (error) {
      // Track form error
      trackFormError(error instanceof Error ? error.message : 'Unknown error', {
        service: finalService,
        location: props.locationName || 'unknown',
        page_url: window.location.href,
      });
      throw error;
    }
  };

  const handleRetry = () => {
    if (formRef.current) {
      // Track retry attempt
      trackFormSubmit({
        form_name: formName,
        service: formDataRef.current.service,
        location: props.locationName || 'unknown',
        page_url: window.location.href,
        is_retry: true,
        file_count: uploadedFileUrls.length,
      });

      retrySubmission(formRef.current, formDataRef.current);
    }
  };

  // Show submission status if not idle
  if (submissionState !== 'idle') {
    return (
      <div className='bg-white text-gray-900 rounded-lg shadow-xl overflow-hidden'>
        {!hideHeader && <FormHeader />}
        <div className='p-6'>
          <SubmissionStatus
            state={submissionState as 'submitting' | 'success' | 'error'}
            clientName={clientName}
            leadId={leadId}
            error={submissionError}
            onRetry={handleRetry}
            onReset={resetForm}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className={`bg-white text-gray-900 ${
        !hideHeader ? 'rounded-lg shadow-xl overflow-hidden' : ''
      }`}>
      {!hideHeader && <FormHeader />}
      <form ref={formRef} onSubmit={handleFormSubmit} className='p-6 space-y-4'>
        <PersonalInfoFields />

        <ServiceSelectionFields
          selectedService={selectedService}
          onServiceChange={(value) => handleServiceChange({ target: { value } } as React.ChangeEvent<HTMLSelectElement>)}
          showOtherServiceInput={showOtherServiceInput}
          otherService={otherService}
          onOtherServiceChange={handleOtherServiceChange}
          predefinedServices={predefinedServices}
        />

        <MessageField />

        <FileUploadWithProgress
          files={files}
          onFilesChange={handleFilesChange}
          onRemoveFile={removeFile}
          onUploadComplete={handleFileUploadComplete}
          isUploading={isUploading}
          setIsUploading={setIsUploading}
        />

        <SubmitButton
          isSubmitting={isSubmitting}
          disabled={
            isUploading ||
            (files.length > 0 && uploadedFileUrls.length < files.length)
          }
        />
      </form>
    </div>
  );
};

export default ContactRequestFormRefactored;
