import React, { useEffect, useState } from 'react';
import {
  Routes,
  Route,
  Navigate,
  useParams,
  useLocation,
} from 'react-router-dom';
import { ThemeProvider } from './components/theme-provider';
import { Toaster } from '@/components/ui/sonner';
import ScrollToTop from './components/ScrollToTop';
import { ModalProvider } from './components/shared/modal';
import ContactFormModal from './components/shared/modal/ContactFormModal';
import { TrackingProvider } from './components/tracking/TrackingProvider';
import { supabase } from './lib/supabase';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './lib/queryClient';
import { HelmetProvider } from 'react-helmet-async';
import {
  PUBLIC_ROUTES,
  AUTH_ROUTES,
  ADMIN_ROUTES,
  SPECIAL_ROUTES,
} from './routes';

// Import public pages
import Home from './pages/Index';
import About from './pages/About';
import Services from './pages/ServicesHub';
import ServiceDetail from './pages/ServiceDetail';
import LocationServiceDetail from './pages/LocationServiceDetail';
import ServicesByLocation from './pages/ServicesByLocation';
import Contact from './pages/Contact';
import Gallery from './pages/GalleryPage';
import GalleryDetail from './pages/GalleryDetail';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import NotFound from './pages/NotFound';
import LocationDetail from './pages/LocationDetail';
import Locations from './pages/Locations';
import Reviews from './pages/Reviews';
import Projects from './pages/Projects';
import WSIB from './pages/WSIB';
import SiteMap from './pages/SiteMap';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';

// Import admin pages
import AdminLogin from './pages/AdminLogin';
import AdminDashboard from './pages/AdminDashboard';
import AdminGalleries from './pages/AdminGalleries';
import AdminGalleryEdit from './pages/AdminGalleryEdit';
import AdminGalleryPhotos from './pages/AdminGalleryPhotos';
import AdminSettings from './pages/AdminSettings';
import AdminLocations from './pages/AdminLocations';
import AdminEditLocation from './pages/AdminEditLocation';
import AdminProjects from './pages/AdminProjects';
import AdminEditProject from './pages/AdminEditProject';
import AdminServices from './pages/AdminServices';
import AdminEditService from './pages/AdminEditService';
import AdminStaticPages from './pages/AdminStaticPages';
import AdminEditPage from './pages/AdminEditPage';
import AdminFranchiseImages from './pages/AdminFranchiseImages';
import AdminLeads from './pages/AdminLeads';
import AdminReviews from './pages/AdminReviews';
import AdminGMBLocations from './pages/AdminGMBLocations';
import AdminWordPressMigration from './pages/AdminWordPressMigration';
import AdminWSIB from './pages/AdminWSIB';
import AdminInviteUser from './pages/AdminInviteUser';
import AdminBlog from './pages/AdminBlog';
import AdminBlogPosts from './pages/AdminBlogPosts';
import AdminBlogPost from './pages/AdminBlogPost';
import AdminBlogCategories from './pages/AdminBlogCategories';
import AdminBlogTags from './pages/AdminBlogTags';
import ResetPassword from './pages/ResetPassword';
import AdminAssets from './pages/AdminAssets';
import TableStandardsDemo from './pages/TableStandardsDemo';
import AdminLocationServices from './pages/AdminLocationServices';
import AdminEditLocationService from './pages/AdminEditLocationService';

const LegacyServiceEditRedirect = () => {
  const { id } = useParams();
  return <Navigate to={`/admin/edit-service/${id}`} replace />;
};

const App = () => {
  const [settings, setSettings] = useState<any>(null);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const { data, error } = await supabase
          .from('site_settings')
          .select('*')
          .single();

        if (error) {
          console.error('Error fetching site settings:', error);
          return;
        }

        setSettings(data);
      } catch (err) {
        console.error('Failed to fetch site settings:', err);
      }
    };

    fetchSettings();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <ThemeProvider defaultTheme='light' storageKey='ui-theme'>
          <TrackingProvider settings={settings || {}}>
            <ModalProvider>
              <div className='App'>
                <ScrollToTop />
                <Routes>
                  <Route path='/' element={<Home />} />
                  <Route path='/about' element={<About />} />
                  <Route
                    path='/about-us'
                    element={<Navigate to='/about' replace />}
                  />

                  <Route path='/our-services' element={<Services />} />
                  <Route
                    path='/services'
                    element={<Navigate to='/our-services' replace />}
                  />
                  <Route path='/services/:slug' element={<ServiceDetail />} />
                  <Route
                    path='/our-services/:slug'
                    element={<ServiceDetail />}
                  />

                  <Route path='/contact' element={<Contact />} />
                  <Route path='/gallery' element={<Gallery />} />
                  <Route path='/gallery/:slug' element={<GalleryDetail />} />
                  <Route
                    path='/galleries'
                    element={<Navigate to='/gallery' replace />}
                  />
                  <Route path='/blog' element={<Blog />} />
                  <Route path='/blog/:slug' element={<BlogPost />} />
                  <Route path='/locations' element={<Locations />} />
                  <Route
                    path='/locations/:locationSlug'
                    element={<LocationDetail />}
                  />
                  <Route
                    path='/locations/:locationSlug/:sublocationSlug'
                    element={<LocationDetail />}
                  />
                  <Route path='/reviews' element={<Reviews />} />
                  <Route path='/wsib' element={<WSIB />} />
                  <Route path='/projects' element={<Projects />} />
                  <Route path='/projects/:slug' element={<Projects />} />
                  <Route path='/SiteMap' element={<SiteMap />} />
                  <Route path='/sitemap' element={<SiteMap />} />
                  <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                  <Route
                    path='/terms-of-service'
                    element={<TermsOfService />}
                  />
                  <Route
                    path='/services-by-location'
                    element={<ServicesByLocation />}
                  />
                  <Route path='/admin/login' element={<AdminLogin />} />
                  <Route
                    path='/admin/reset-password'
                    element={<ResetPassword />}
                  />

                  <Route path='/admin' element={<AdminDashboard />} />
                  <Route path='/admin/login' element={<AdminLogin />} />
                  <Route
                    path='/admin/reset-password'
                    element={<ResetPassword />}
                  />
                  <Route
                    path={ADMIN_ROUTES.INVITE}
                    element={<AdminInviteUser />}
                  />
                  <Route
                    path={ADMIN_ROUTES.SERVICES}
                    element={<AdminServices />}
                  />
                  <Route
                    path={ADMIN_ROUTES.EDIT_SERVICE}
                    element={<AdminEditService />}
                  />
                  <Route
                    path='/admin/services/edit/:id'
                    element={<LegacyServiceEditRedirect />}
                  />
                  <Route
                    path={ADMIN_ROUTES.LOCATIONS}
                    element={<AdminLocations />}
                  />
                  <Route
                    path='/admin/edit-location/:id'
                    element={<AdminEditLocation />}
                  />
                  <Route path='/admin/projects' element={<AdminProjects />} />
                  <Route
                    path='/admin/edit-project/:id'
                    element={<AdminEditProject />}
                  />
                  <Route path='/admin/reviews' element={<AdminReviews />} />
                  <Route path='/admin/leads' element={<AdminLeads />} />
                  <Route path='/admin/settings' element={<AdminSettings />} />
                  <Route path='/admin/wsib' element={<AdminWSIB />} />
                  <Route
                    path='/admin/gmb-locations'
                    element={<AdminGMBLocations />}
                  />
                  <Route path='/admin/blog' element={<AdminBlog />} />
                  <Route
                    path='/admin/blog/posts'
                    element={<AdminBlogPosts />}
                  />
                  <Route
                    path='/admin/blog/post/:id'
                    element={<AdminBlogPost />}
                  />
                  <Route
                    path='/admin/blog/categories'
                    element={<AdminBlogCategories />}
                  />
                  <Route path='/admin/blog/tags' element={<AdminBlogTags />} />
                  <Route
                    path='/admin/static-pages'
                    element={<AdminStaticPages />}
                  />
                  <Route
                    path='/admin/edit-page/:id'
                    element={<AdminEditPage />}
                  />
                  <Route path='/admin/galleries' element={<AdminGalleries />} />
                  <Route
                    path='/admin/gallery/edit/:id'
                    element={<AdminGalleryEdit />}
                  />
                  <Route
                    path='/admin/gallery/photos/:id'
                    element={<AdminGalleryPhotos />}
                  />
                  <Route
                    path='/admin/franchise-images'
                    element={<AdminFranchiseImages />}
                  />
                  <Route path='/admin/assets' element={<AdminAssets />} />
                  <Route
                    path='/admin/migration'
                    element={<AdminWordPressMigration />}
                  />
                  <Route
                    path='/admin/table-standards'
                    element={<TableStandardsDemo />}
                  />
                  <Route
                    path={ADMIN_ROUTES.LOCATION_SERVICES}
                    element={<AdminLocationServices />}
                  />
                  <Route
                    path={ADMIN_ROUTES.EDIT_LOCATION_SERVICE}
                    element={<AdminEditLocationService />}
                  />

                  <Route
                    path='/admin/dashboard'
                    element={<Navigate to='/admin' replace />}
                  />

                  <Route
                    path={PUBLIC_ROUTES.LOCATION_SERVICE_DETAIL}
                    element={<LocationServiceDetail />}
                  />

                  <Route path='*' element={<NotFound />} />
                </Routes>
                <Toaster />
                <ContactFormModal />
              </div>
            </ModalProvider>
          </TrackingProvider>
        </ThemeProvider>
      </HelmetProvider>
    </QueryClientProvider>
  );
};

export default App;
