
import React, { useState, useRef, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import {
  Upload,
  X,
  Loader2,
  CheckCircle2,
  AlertCircle,
  Image as ImageIcon,
  Info,
  FileText,
} from 'lucide-react';
import { toast } from 'sonner';
import { uploadFile, CONTACT_UPLOADS_BUCKET } from '@/utils/imageUpload';

interface FileUploadWithProgressProps {
  files: File[];
  onFilesChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onRemoveFile: (index: number) => void;
  onUploadComplete: (urls: string[]) => void;
  isUploading: boolean;
  setIsUploading: (isUploading: boolean) => void;
}

interface FileWithPreview extends File {
  preview?: string;
}

export const FileUploadWithProgress: React.FC<FileUploadWithProgressProps> = ({
  files,
  onFilesChange,
  onRemoveFile,
  onUploadComplete,
  isUploading,
  setIsUploading,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [filesWithPreviews, setFilesWithPreviews] = useState<FileWithPreview[]>(
    []
  );
  const [progress, setProgress] = useState<{
    completed: number;
    total: number;
  }>({
    completed: 0,
    total: 0,
  });
  const [uploadErrors, setUploadErrors] = useState<string[]>([]);
  const [hasRestoredFiles, setHasRestoredFiles] = useState(false);

  useEffect(() => {
    // Generate previews for image files
    const generatePreviews = async () => {
      const newFilesWithPreviews = await Promise.all(
        files.map(async (file) => {
          if (file.type.startsWith('image/')) {
            const preview = URL.createObjectURL(file);
            return Object.assign(file, { preview });
          }
          return file;
        })
      );
      setFilesWithPreviews(newFilesWithPreviews);
    };

    if (files.length > 0) {
      generatePreviews();
    } else {
      setFilesWithPreviews([]);
    }

    // Cleanup previews on unmount
    return () => {
      filesWithPreviews.forEach((file) => {
        if (file.preview) {
          URL.revokeObjectURL(file.preview);
        }
      });
    };
  }, [files]);

  const handleUploadClick = async () => {
    if (files.length === 0) return;

    setIsUploading(true);
    setUploadErrors([]);
    const urls: string[] = [];
    const errors: string[] = [];

    try {
      // Upload each file one by one
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        setProgress({
          completed: i,
          total: files.length,
        });

        // Upload file using the standardized utility
        const { url, error } = await uploadFile(file, {
          bucket: CONTACT_UPLOADS_BUCKET,
          folderPath: 'contact-form',
          maxSize: 15 * 1024 * 1024 // 15MB limit
        });

        if (error) {
          errors.push(`Error uploading ${file.name}: ${error}`);
          toast.error(`Failed to upload ${file.name}: ${error}`);
        } else if (url) {
          urls.push(url);
          console.log(`Uploaded ${file.name}: ${url}`);
        }
      }

      if (errors.length > 0) {
        setUploadErrors(errors);
      }

      onUploadComplete(urls);
    } catch (error) {
      console.error('Error uploading files:', error);
      setUploadErrors([
        `Upload failed: ${
          error instanceof Error ? error.message : 'Unknown error'
        }`,
      ]);
    } finally {
      setIsUploading(false);
      setProgress({
        completed: files.length,
        total: files.length,
      });
    }
  };

  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const progressPercentage =
    progress.total > 0
      ? Math.round((progress.completed / progress.total) * 100)
      : 0;

  // Function to render the appropriate icon based on file type
  const renderFileIcon = (file: FileWithPreview) => {
    if (file.preview) {
      return (
        <div className='h-12 w-12 flex-shrink-0 rounded overflow-hidden'>
          <img
            src={file.preview}
            alt={file.name}
            className='h-full w-full object-cover'
          />
        </div>
      );
    } else if (file.type.includes('pdf')) {
      return (
        <div className='h-12 w-12 flex-shrink-0 rounded bg-muted flex items-center justify-center'>
          <FileText className='h-6 w-6 text-red-500' />
        </div>
      );
    } else if (file.type.includes('document') || file.type.includes('msword')) {
      return (
        <div className='h-12 w-12 flex-shrink-0 rounded bg-muted flex items-center justify-center'>
          <FileText className='h-6 w-6 text-blue-500' />
        </div>
      );
    } else {
      return (
        <div className='h-12 w-12 flex-shrink-0 rounded bg-muted flex items-center justify-center'>
          <ImageIcon className='h-6 w-6 text-muted-foreground' />
        </div>
      );
    }
  };

  return (
    <div className='space-y-4'>
      {files.length > 0 ? (
        <div className='border rounded-md p-4 space-y-4'>
          <div className='flex justify-between items-center'>
            <h4 className='font-medium'>{files.length} file(s) selected</h4>
            {!isUploading && (
              <Button
                type='button'
                variant='outline'
                size='sm'
                onClick={openFileDialog}>
                Add more
              </Button>
            )}
          </div>

          {hasRestoredFiles && (
            <div className='bg-blue-50 text-blue-700 p-3 rounded text-sm flex items-start gap-2'>
              <Info className='h-4 w-4 mt-0.5 flex-shrink-0' />
              <div>
                Your previously selected files have been restored. You'll need
                to upload them again if you want to submit the form.
              </div>
            </div>
          )}

          <div className='space-y-2 max-h-40 overflow-y-auto'>
            {filesWithPreviews.map((file, index) => (
              <div
                key={`${file.name}-${index}`}
                className='flex items-center gap-3 p-2 bg-muted/30 rounded'>
                {renderFileIcon(file)}
                <div className='flex-1 min-w-0'>
                  <div className='truncate text-sm'>{file.name}</div>
                  <div className='text-xs text-muted-foreground'>
                    {(file.size / 1024).toFixed(0)}KB
                  </div>
                </div>
                {!isUploading && (
                  <Button
                    type='button'
                    variant='ghost'
                    size='sm'
                    onClick={() => onRemoveFile(index)}
                    className='h-8 w-8 p-0 flex-shrink-0'>
                    <X className='h-4 w-4' />
                  </Button>
                )}
              </div>
            ))}
          </div>

          {uploadErrors.length > 0 && (
            <div className='bg-destructive/10 text-destructive p-3 rounded text-sm'>
              {uploadErrors.map((error, i) => (
                <div key={i} className='flex items-center gap-2'>
                  <AlertCircle className='h-4 w-4' />
                  {error}
                </div>
              ))}
            </div>
          )}

          {isUploading && (
            <div className='space-y-2'>
              <div className='h-2 bg-muted rounded-full overflow-hidden'>
                <div
                  className='h-full bg-primary transition-all duration-300'
                  style={{ width: `${progressPercentage}%` }}
                />
              </div>
              <div className='text-sm text-muted-foreground text-center'>
                Uploading... {progressPercentage}%
              </div>
            </div>
          )}

          {!isUploading && files.length > 0 && (
            <Button
              type='button'
              onClick={handleUploadClick}
              className='w-full'
              disabled={isUploading}>
              {isUploading ? (
                <Loader2 className='h-4 w-4 animate-spin mr-2' />
              ) : (
                <Upload className='h-4 w-4 mr-2' />
              )}
              Upload Files
            </Button>
          )}
        </div>
      ) : (
        <div className='border border-dashed rounded-md p-6 text-center space-y-2'>
          <div className='flex justify-center'>
            <Upload className='h-8 w-8 text-muted-foreground' />
          </div>
          <div>
            <Button type='button' variant='outline' onClick={openFileDialog}>
              Select Files
            </Button>
          </div>
          <p className='text-sm text-muted-foreground'>
            Add up to 5 images or documents (max 15MB each)
          </p>
        </div>
      )}

      <input
        ref={fileInputRef}
        type='file'
        multiple
        accept='image/*,application/pdf,.doc,.docx'
        className='hidden'
        onChange={onFilesChange}
        disabled={isUploading}
      />
    </div>
  );
};
