import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Layout } from '@/components/Layout';
import { useServiceQuery } from '@/hooks/useServiceQuery';
import LoadingState from '@/components/service/LoadingState';
import NotFoundState from '@/components/service/NotFoundState';
import ServiceHero from '@/components/service/ServiceHero';
import ServiceDescription from '@/components/service/ServiceDescription';
import ServiceBenefits from '@/components/service/ServiceBenefits';
import ServiceFAQ from '@/components/service/ServiceFAQ';
import ServiceCTA from '@/components/service/ServiceCTA';
import ServiceProcessSteps from '@/components/service/ServiceProcessSteps';
import ServiceGallery from '@/components/service/ServiceGallery';
// Removed ServiceReviews import as it's no longer used
import PageMeta from '@/components/SEO/PageMeta';
import Breadcrumbs from '@/components/SEO/Breadcrumbs';
import { useQueryClient } from '@tanstack/react-query';
import {
  useContentTracking,
  useScrollTracking,
} from '@/hooks/useEventTracking';
import { withPageTracking } from '@/components/tracking/TrackingProvider';
import { useTrackingParams } from '@/hooks/useTrackingParams';
// Import schema utilities from centralized index
import {
  getSchemaContext,
  getServiceSchema,
  getFAQSchema,
  FAQItem,
} from '@/components/SEO/schema';
import { Helmet } from 'react-helmet-async';

const ServiceDetail = () => {
  // Get the service slug from URL parameters
  const { slug } = useParams<{ slug: string }>();
  const queryClient = useQueryClient();

  // Get tracking parameters
  const trackingParams = useTrackingParams();

  // Use the custom hook to fetch service data
  const { data: service, isLoading, error } = useServiceQuery(slug || '');

  // Initialize tracking hooks
  const { trackServiceView } = useContentTracking();

  // Initialize scroll tracking with predefined thresholds
  useScrollTracking([25, 50, 75, 90]);

  // Prefetch related services when service loads and track service view
  useEffect(() => {
    if (service) {
      // Prefetch all services to allow quick navigation between services
      queryClient.prefetchQuery({
        queryKey: ['services'],
        queryFn: ({ signal }) =>
          import('@/lib/supabase/services').then((module) =>
            module.fetchServices(signal)
          ),
      });

      // Track service view with tracking parameters
      trackServiceView(
        String(service.id), // Convert id to string to satisfy type requirements
        service.title,
        {
          service_slug: service.slug,
          service_type: service.title, // Use title instead of category which doesn't exist
          has_gallery: !!service.gallery_images?.length,
          has_reviews: !!service.review_ids?.length,
          is_featured: service.is_featured || false,
          ...trackingParams, // Include UTM and other tracking params
        }
      );
    }
  }, [service, queryClient, trackServiceView, trackingParams]);

  // Show loading state while fetching
  if (isLoading) {
    return <LoadingState />;
  }

  // Show not found state if service doesn't exist or there was an error
  if (error || !service) {
    return <NotFoundState />;
  }

  // Generate schema data for the service
  const schemaContext = getSchemaContext();

  // Get service schema
  const serviceSchema = getServiceSchema(service, schemaContext);

  // If there are FAQs, also generate FAQ schema
  let schemaToUse = serviceSchema;
  if (service.faq && service.faq.length > 0) {
    // Create a separate FAQ schema using our utility
    const faqSchema = getFAQSchema(service.faq as FAQItem[], schemaContext);

    // Use the service schema as our primary schema
    // (FAQ schema will be added as a separate script tag in the HTML)
    schemaToUse = serviceSchema;
  }

  return (
    <Layout>
      <PageMeta
        title={service.meta_title || `${service.title} | Fix My Brick`}
        description={service.meta_description || service.description}
        canonical={service.canonical_url || `/services/${service.slug}`}
        image={service.og_image || service.image_url}
        type='website'
        schema={schemaToUse}
      />

      {/* Add FAQ schema separately if it exists */}
      {service.faq && service.faq.length > 0 && (
        <Helmet>
          <script type='application/ld+json'>
            {JSON.stringify(
              getFAQSchema(service.faq as FAQItem[], schemaContext)
            )}
          </script>
        </Helmet>
      )}

      <div className='container mx-auto px-4'>
        <Breadcrumbs
          items={[
            { label: 'Services', path: '/services' },
            { label: service.title, path: `/services/${service.slug}` },
          ]}
          className='py-4'
        />
      </div>

      <ServiceHero service={service} />

      <ServiceDescription service={service} />

      {service.benefits && service.benefits.length > 0 && (
        <ServiceBenefits service={service} />
      )}

      {service.process_steps && service.process_steps.length > 0 && (
        <ServiceProcessSteps service={service} />
      )}

      {service.gallery_images && service.gallery_images.length > 0 && (
        <ServiceGallery service={service} />
      )}

      {/* Removed duplicate ServiceReviews section - reviews are now only shown in ServiceHero */}

      {service.faq && service.faq.length > 0 && (
        <ServiceFAQ service={service} />
      )}

      <ServiceCTA service={service} />
    </Layout>
  );
};

// Wrap with page tracking HOC
export default withPageTracking(ServiceDetail, 'service_page');
